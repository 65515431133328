import styled from "styled-components"

import { colors } from '~/styles';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px 40px 120px;
  width: 100%;

  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0 40px;
    max-width: 1200px;

    > section {
      display: flex;
      flex-direction: column;

      strong {
        color: ${colors.title};
        font-size: 16px;
        font-weight: 800;
        margin-bottom: 30px;
        text-transform: uppercase;
      }

      a {
        color: ${colors.white};
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;

        &:hover {
          color: ${colors.success};
          transition: all 0.3s;
        }
      }

      img {
        max-width: 220px;
        width: 100%;
      }
    }

    @media (max-width: 768px) {
        flex-wrap: wrap;

        > section {
          margin-bottom: 40px;  
        }
      }
  }
`
