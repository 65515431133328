import React, { useEffect, useState, useMemo } from "react"

import api from "~/services/api"

import Markdown from "react-markdown"

import { Container, Challenges, Databases } from "./styles"

export default function ChallengesPage() {
  return (
    <Container id="desafios">
      <div>
        <h2>Desafios</h2>
        <Markdown>
          {`**Equilíbrio entre Economia e Isolamento Social:**\nDurante a pandemia
          da COVID-19, o isolamento social foi destacado como o principal método
          para desacelerar a transmissão do novo coronavírus (estratégia
          conhecida como achatamento da curva). Se, por um lado, praticar o
          isolamento é importante (o Brasil já conta com mais de 65 mil vítimas
          fatais da nova doença), por outro o fechamento do comércio não
          essencial traz grandes impactos sociais, como o desemprego (o Mercado
          estima uma queda de 6,5% no PIB em 2020). Diante desse cenário, como
          podemos usar a tecnologia e o poder dos dados para desenvolver
          ferramentas e/ou mecanismos que melhorem o equilíbrio entre Economia e
          Saúde? \n \n**Recuperação do Setor da Moda:**\nLevantamento feito pela
          Revista Goiás Industrial aponta que a produção da região da 44 geram
          movimentação financeira próxima de R$ 600 milhões por mês, ou seja,
          quase R$ 7 bilhões por ano. O valor corresponde a aproximadamente 3,5%
          do Produto Interno Bruto (PIB) gerado em todo o Estado. Devido a atual
          crise provocada pela pandemia da Covid19, 10% dos lojistas da região
          estão em dificuldade. O fechamento dessas lojas resulta na perda de 20
          mil empregos diretos e indiretos, e um evidente prejuízo a economia do
          estado. A indústria da moda teve a produção paralisada e
          consequentemente os comerciantes varejistas se viram forçados a fechar
          ou reduzir suas atividades. Diante desse cenário, e utilizando a
          tecnologia, como podemos recuperar o comércio varejista da 44 e
          realocar os profissionais que se encontram disponíveis no mercado, em
          um cenário pós-pandemia?\n \n \n \n**Prevendo surtos de Dengue:**\nPor
          conta das características climáticas de Goiás (tempo quente e
          chuvoso), a Dengue é uma doença que exige esforço constante dos
          gestores de saúde goianos. Apesar de não ser novidade, de tempos em
          tempos alguns municípios acabam sofrendo com surto da doença, sendo
          este causado por uma combinação de diferentes fatores (como infestação
          predial pelo vetor Aedes aegypti, fatores climáticos e sorotipo
          circulante).\nCom base nos dados históricos sobre a Dengue nos
          municípios goianos, seria possível desenvolver uma plataforma que
          pudesse prever quando ocorrerá um novo surto da doença?\n \n**Padrão
          de óbitos por município:**\nCom base nos dados anuais sobre óbitos por
          município, podemos observar algum padrão relacionado as causas dos
          óbitos? Esse padrão vem mudando com o passar do tempo? Alguma doença
          está vitimando mais pessoas do que no passado? Para os gestores
          estaduais e municipais de saúde, ter essas informações em mãos pode
          fazer toda a diferença no planejamento estratégico. Como poderíamos
          utilizar a tecnologia para encontrar insights automaticamente nesses
          dados e disponibilizá-los aos tomadores de decisão de forma rápida e
          descomplicada?\n \n \n**Desafios da Agroindústria:**\nGoiás apresenta
          extensas áreas de pastagens e lavouras. Quase metade do território
          goiano é formada por propriedades com mais de mil hectares. A
          agropecuária goiana tem grande importância no cenário econômico
          nacional, uma vez que sua produção de carnes e grãos impulsiona a
          exportação estadual. O estado é um dos maiores produtores de tomate,
          milho e soja do Brasil. Responsável por 33% da produção nacional de
          sorgo, Goiás é o principal produtor desse grão no país. Outros
          cultivos importantes são: algodão, cana-de-açúcar, café, arroz,
          feijão, trigo e alho. De acordo com a Secretaria de Comércio Exterior
          (Secex), ligada ao Ministério da Economia, do fim de 2017 até o ano
          passado, a fatia de exportações destinadas a somente dois países
          passou de 34,2% para 41,3%. Isso demonstra que o país se encontra
          altamente depende dessas duas outras economias. Considerando que a
          maioria dessas exportações se trata de produtos de baixo valor
          agregado, como a agroindústria, utilizando a tecnologia pode trabalhar
          os seus produtos com o objetivo de diminuir a independência financeira
          com alguns países e aumentar o valor agregado dos seus produtos,
          carteira de clientes e expansão da sua produção?\n \n \n**Desafio da
          Agricultura Familiar:**\nDe acordo com a revista Campo, mais de 80 mil
          produtores familiares de Goiás não conseguem disponibilizar seus
          produtos em plataforma online para venda. Além deste problema, com a
          Pandemia provocada pelo COVID-19 as aulas foram suspensas o que
          diminuiu a demanda por merenda escolar provocando a necessidade de
          pequenos produtores encontrarem outras plataformas para venda de seus
          produtos. Diante desse cenário, e utilizando a tecnologia proponha
          formas de fazer os produtos produzidos no campo pelos agricultores
          familiares chegarem às casas dos consumidores considerando o shelflife
          de cada um.\n \n \n\n**Desafio da Bovinocultura de corte:**\nA
          implantação da rastreabilidade no Brasil provocou mudanças no hábito
          dos pecuaristas e determinou os condicionantes que levaram à
          implantação de novos processos nas etapas de produção da carne bovina
          no Brasil. De acordo com a revista Campo, ainda há desafios na área da
          rastreabilidade pois implica a adoção de tecnologias, sensores e
          treinamento. Além do mais, a atual rastreabilidade ainda não resolve o
          problema do furto de animais. Neste contexto e utilizando a
          tecnologia, proponha melhorias ao sistema e ao método utilizado para
          rastrear bovinos.\n`}
        </Markdown>

        <h3>Críterios de Avaliação</h3>
        <Markdown>
          {`Os inscritos se organizarão em equipes com a ajuda da PRTE, pela
          [plataforma Discord](https://discord.io/campuspartybra) . Depois de
          discutir qual desafio topar, vão formular a proposta de solução a ser
          apresentada.\n\nA melhores 10 propostas serão escolhidas com base nos
          seguintes critérios:\n\n- Proposta de Valor\n- Criatividade\n- Inovação\n- Possibilidade de Implementação\n- Uso dos recursos
          tecnológicos disponibilizados.`}
        </Markdown>

        <h3>Central de Dados</h3>
        <Markdown>
          {`[https://dadosabertos.go.gov.br/](https://dadosabertos.go.gov.br/)`}
        </Markdown>
      </div>
    </Container>
  )
}
