import React from "react"
import GlobalStyle from "~/styles/global"

import { Container } from "./styles"

import Header from "./Header"
import Footer from "./Footer"

export default function Layout({ links, children }) {
  return (
    <>
      <GlobalStyle />

      <Container>
        <Header links={links} />
        <main>{children}</main>
        <Footer links={links} />
      </Container>
    </>
  )
}
