import styled, { css } from "styled-components"

import { colors } from "~/styles"

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  width: 100%;

  > div {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    padding: 40px;
    width: 100%;
  }
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;

  a {
    align-items: center;
    background: ${colors.yellow};
    border-radius: 6px;
    color: ${colors.primary} !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* height: 50px; */
    margin-right: 10px;
    padding: 20px 20px;

    &:last-child {
      background: ${colors.secondary};
      margin-right: 0;
    }

    strong {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 8px;
      text-transform: uppercase;
    }

    span {
      font-size: 12px;
      font-weight: 300;
    }
  }

  @media (max-width: 578px) {
    flex-direction: column;

    a {
      margin-bottom: 20px;
      width: 100%;
    }
  }
`

export const Steps = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;

  > a {
    background: ${colors.white};
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    position: relative;
    margin-right: 50px;

    &::after {
      color: ${colors.white};
      content: "→";
      font-size: 40px;
      position: absolute;
      top: 50%;
      right: -45px;
      z-index: 11;
      transform: translateY(-50%);
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    &:last-child {
      margin-right: 0;

      &::after {
        content: "";
      }
    }

    strong {
      color: ${colors.primary};
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 5px;
      text-transform: uppercase;
    }

    span {
      color: ${colors.black};
      font-size: 12px;
      font-weight: 300;
    }
  }
`
