import styled from "styled-components"

import { colors } from "~/styles"

export const Container = styled.div`
  background: ${colors.white};
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  width: 100%;

  > div {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    padding: 40px;
    width: 100%;

    h2 {
      margin-bottom: 40px;
    }
  }
`

export const Companies = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 100px;
  width: 100%;

  img {
    display: flex;
    margin: 0 auto 40px;
    max-height: 80px;
    object-fit: contain;
    text-align: center;
    width: 80%;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 577px) {
    grid-template-columns: 1fr;

    img {
      text-align: left;
      margin: 0 0 40px;
      width: auto;
    }
  }
`
