import { createGlobalStyle } from "styled-components"

import colors from "./colors"

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Raleway:300,400,400i,600,700&display=swap');

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  *:focus {
    outline: 0;
  }

  html, body, #root {
    height: 100%;
    scroll-behavior: smooth;
  }

  body {
    background: ${colors.lightGray};
    -webkit-font-smoothing: antialiased;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  body, input, textarea, button {
    font: 14px Roboto, sans-serif;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input, button {
    background: transparent;
    border: 0;
    border-radius: 0;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  button {
    cursor :pointer;
  }
`
