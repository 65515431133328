import React, { useEffect, useState, useMemo } from "react"

import api from "~/services/api"

import Markdown from "react-markdown"

import { FaLinkedin, FaGithub } from "react-icons/fa"

import { Container, People } from "./styles"

const evaluators = [
  {
    id: 1,
    avatar: {
      id: 65,
      name: "Renildo Marques Teixeira",
      alternativeText: "",
      caption: "",
      width: 484,
      height: 485,
      formats: {
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_Renildo_Marques_Teixeira_ccd7f5437f.jpeg",
          hash: "thumbnail_Renildo_Marques_Teixeira_ccd7f5437f",
          mime: "image/jpeg",
          path: null,
          size: 2.83,
          width: 156,
          height: 156,
        },
      },
      hash: "Renildo_Marques_Teixeira_ccd7f5437f",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 14.1,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/Renildo_Marques_Teixeira_ccd7f5437f.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-10T11:28:13.888Z",
      updated_at: "2020-07-10T11:28:13.888Z",
    },
    name: "Renildo Marques Teixeira",
    company: "Gerente de Promoção Social do Senar Goiás",
    linkedin: null,
    github: null,
    created_at: "2020-07-10T11:28:29.997Z",
    updated_at: "2020-07-10T11:28:29.997Z",
  },
  {
    id: 2,
    avatar: {
      id: 66,
      name: "Anderson_Soares-683x1024",
      alternativeText: "",
      caption: "",
      width: 683,
      height: 1024,
      formats: {
        large: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/large_Anderson_Soares_683x1024_797cff6676.jpeg",
          hash: "large_Anderson_Soares_683x1024_797cff6676",
          mime: "image/jpeg",
          path: null,
          size: 56.94,
          width: 667,
          height: 1000,
        },
        small: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/small_Anderson_Soares_683x1024_797cff6676.jpeg",
          hash: "small_Anderson_Soares_683x1024_797cff6676",
          mime: "image/jpeg",
          path: null,
          size: 19.81,
          width: 333,
          height: 500,
        },
        medium: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/medium_Anderson_Soares_683x1024_797cff6676.jpeg",
          hash: "medium_Anderson_Soares_683x1024_797cff6676",
          mime: "image/jpeg",
          path: null,
          size: 37.02,
          width: 500,
          height: 750,
        },
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_Anderson_Soares_683x1024_797cff6676.jpeg",
          hash: "thumbnail_Anderson_Soares_683x1024_797cff6676",
          mime: "image/jpeg",
          path: null,
          size: 3.71,
          width: 104,
          height: 156,
        },
      },
      hash: "Anderson_Soares_683x1024_797cff6676",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 59.06,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/Anderson_Soares_683x1024_797cff6676.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-10T11:29:01.385Z",
      updated_at: "2020-07-10T11:29:01.385Z",
    },
    name: "Anderson da Silva Soares",
    company:
      "Professor da UFG e Coordenador do Centro de Excelência em Inteligência Artificial",
    linkedin: null,
    github: null,
    created_at: "2020-07-10T11:29:16.912Z",
    updated_at: "2020-07-10T11:29:16.912Z",
  },
  {
    id: 3,
    avatar: {
      id: 67,
      name: "Robson Vieiria",
      alternativeText: "",
      caption: "",
      width: 640,
      height: 640,
      formats: {
        small: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/small_Robson_Vieiria_3958e99b4e.jpeg",
          hash: "small_Robson_Vieiria_3958e99b4e",
          mime: "image/jpeg",
          path: null,
          size: 32.6,
          width: 500,
          height: 500,
        },
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_Robson_Vieiria_3958e99b4e.jpeg",
          hash: "thumbnail_Robson_Vieiria_3958e99b4e",
          mime: "image/jpeg",
          path: null,
          size: 5.8,
          width: 156,
          height: 156,
        },
      },
      hash: "Robson_Vieiria_3958e99b4e",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 42.52,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/Robson_Vieiria_3958e99b4e.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-10T11:29:43.449Z",
      updated_at: "2020-07-10T11:29:43.449Z",
    },
    name: "Robson Domingos Vieira",
    company: "Engenheiro Eletricista e Presidente da FAPEG",
    linkedin: null,
    github: null,
    created_at: "2020-07-10T11:29:57.266Z",
    updated_at: "2020-07-10T11:29:57.266Z",
  },
  {
    id: 4,
    avatar: {
      id: 68,
      name: "Adriana Brito",
      alternativeText: "",
      caption: "",
      width: 247,
      height: 268,
      formats: {
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_Adriana_Brito_b5c3d884a8.jpeg",
          hash: "thumbnail_Adriana_Brito_b5c3d884a8",
          mime: "image/jpeg",
          path: null,
          size: 4.33,
          width: 144,
          height: 156,
        },
      },
      hash: "Adriana_Brito_b5c3d884a8",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 11.57,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/Adriana_Brito_b5c3d884a8.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-10T11:30:21.350Z",
      updated_at: "2020-07-10T11:30:21.350Z",
    },
    name: "Adriana Brito",
    company:
      " Engenharia de Computação e Coordenadora do Escritório de Projetos na Subsecretaria de TI - SEDI",
    linkedin: null,
    github: null,
    created_at: "2020-07-10T11:30:31.528Z",
    updated_at: "2020-07-10T11:30:31.528Z",
  },
  {
    id: 5,
    avatar: {
      id: 69,
      name: "Camilla Costa",
      alternativeText: "",
      caption: "",
      width: 853,
      height: 853,
      formats: {
        small: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/small_Camilla_Costa_d202febf10.jpeg",
          hash: "small_Camilla_Costa_d202febf10",
          mime: "image/jpeg",
          path: null,
          size: 33.45,
          width: 500,
          height: 500,
        },
        medium: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/medium_Camilla_Costa_d202febf10.jpeg",
          hash: "medium_Camilla_Costa_d202febf10",
          mime: "image/jpeg",
          path: null,
          size: 58.45,
          width: 750,
          height: 750,
        },
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_Camilla_Costa_d202febf10.jpeg",
          hash: "thumbnail_Camilla_Costa_d202febf10",
          mime: "image/jpeg",
          path: null,
          size: 6.48,
          width: 156,
          height: 156,
        },
      },
      hash: "Camilla_Costa_d202febf10",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 66.18,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/Camilla_Costa_d202febf10.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-10T11:30:46.970Z",
      updated_at: "2020-07-10T11:30:46.970Z",
    },
    name: "Camilla Costa",
    company: "Gerente Executiva do Sebrae Goiás",
    linkedin: null,
    github: null,
    created_at: "2020-07-10T11:31:08.245Z",
    updated_at: "2020-07-10T11:31:08.245Z",
  },
  {
    id: 6,
    avatar: {
      id: 70,
      name: "Sandra Marcia Silva",
      alternativeText: "",
      caption: "",
      width: 220,
      height: 221,
      formats: {
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_Sandra_Marcia_Silva_9d9c4b91f4.jpeg",
          hash: "thumbnail_Sandra_Marcia_Silva_9d9c4b91f4",
          mime: "image/jpeg",
          path: null,
          size: 6.03,
          width: 155,
          height: 156,
        },
      },
      hash: "Sandra_Marcia_Silva_9d9c4b91f4",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 9.79,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/Sandra_Marcia_Silva_9d9c4b91f4.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-10T11:31:21.002Z",
      updated_at: "2020-07-10T11:31:21.002Z",
    },
    name: "Sandra Marcia Silva",
    company: "Gerente de Desenvolvimento Empresarial",
    linkedin: null,
    github: null,
    created_at: "2020-07-10T11:31:43.364Z",
    updated_at: "2020-07-10T11:31:43.364Z",
  },
]

export default function Evaluators() {
  return (
    <Container id="avaliadores">
      <div>
        <h2>Avaliadores</h2>
        <Markdown>_</Markdown>

        <People>
          {evaluators.map((evaluator, index) => (
            <div key={`evaluator-${String(index)}`}>
              <img
                src={
                  evaluator?.avatar?.formats?.thumbnail?.url ||
                  `https://api.adorable.io/avatars/285/${evaluator.id}@adorable.png`
                }
              />
              <strong>{evaluator.name}</strong>
              <span>{evaluator.company}</span>
              <ul>
                {evaluator.github && (
                  <li>
                    <a href={evaluator.github} target="_blank">
                      <FaGithub />
                    </a>
                  </li>
                )}
                {evaluator.linkedin && (
                  <li>
                    <a href={evaluator.linkedin} target="_blank">
                      <FaLinkedin />
                    </a>
                  </li>
                )}
              </ul>
            </div>
          ))}
        </People>
      </div>
    </Container>
  )
}
