import React from "react"
import { Link } from "gatsby"

import { Container, Companies } from "./styles"

import CampoLab from "~/images/partners/campo-lab.png"
import Fapeg from "~/images/partners/fapeg.png"
import Sedi from "~/images/partners/sedi.png"
import Ceia from "~/images/partners/ceia.png"
import Fieg from "~/images/partners/fieg.png"
import Iel from "~/images/partners/iel.png"
import Sebrae from "~/images/partners/sebrae.png"

const partners = [
  {
    name: "Fapeg",
    link: "http://www.fapeg.go.gov.br/",
    logo: Fapeg,
  },
  {
    name: "Centro de Excelência em IA",
    link: "#",
    logo: Ceia,
  },
  {
    name: "SEDI Goiás",
    link: "https://www.desenvolvimento.go.gov.br/",
    logo: Sedi,
  },
  {
    name: "Campo Lab",
    link: "https://sistemafaeg.com.br/faeg/noticias/campo-lab",
    logo: CampoLab,
  },
  {
    name: "Sebrae",
    link: "https://m.sebrae.com.br/sites/PortalSebrae/ufs/go?codUf=10",
    logo: Sebrae,
  },
  {
    name: "Fieg",
    link: "https://fieg.com.br/",
    logo: Fieg,
  },
]

const communities = [
  // {
  //   name: "Campos Lab",
  //   link: "",
  //   logo: CampoLab,
  //   width: 443,
  // },
]

export default function Supporters() {
  return (
    <Container id="apoio">
      <div>
        <h2>Parceiros</h2>

        <Companies>
          {partners.map((partner, index) => (
            <Link
              href={partner.link}
              target="_blank"
              key={`partner-${String(index)}`}
            >
              <img
                src={partner.logo}
                alt={partner.name}
                // style={{ width: partner.width }}
              />
            </Link>
          ))}
        </Companies>

        {!!communities.length && (
          <>
            <h2>Comunidades</h2>

            <Companies>
              {communities.map((community, index) => (
                <Link
                  href={community.link}
                  target="_blank"
                  key={`community-${String(index)}`}
                >
                  <img
                    src={community.logo}
                    alt={community.name}
                    // style={{ width: community.width }}
                  />
                </Link>
              ))}
            </Companies>
          </>
        )}
      </div>
    </Container>
  )
}
