import React, { useMemo } from "react"

import Markdown from "react-markdown"

import { Container } from "./styles"

export default function Teams() {
  return (
    <Container id="equipes">
      <div>
        <h2>Equipes</h2>
        <Markdown>
          {`Os participantes formarão equipes de no mínimo quatro (4) e no máximo
          cinco (5) integrantes. Não há obrigação, mas o ideal é sejam formadas
          por um integrante de cada área:\n \n- Design – pessoas saibam e
          queira, criar layouts e pensar a forma gráfica da solução.\n- Desenvolvimento – pessoas que saibam e gostem de programar.\n- Análise
          de dados – para aqueles que gostam de observar padrões, criar
          comparações e trabalhar com muita informação.\n- Gestão de ideias –
          para quem tem afinidade com quaisquer dos temas do evento e esteja
          preparado para apresentar solução para banca avaliadora.\n\nVeja que
          para alguns perfis é preciso ter habilidades técnicas específicas.\n`}
        </Markdown>
      </div>
    </Container>
  )
}
