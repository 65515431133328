import styled from "styled-components"

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  width: 100%;

  > div {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    padding: 40px;
    width: 100%;

    > a {
      text-align: center;
      
      img {
        max-width: 400px;
        margin-top: 60px;
      }
    }
  }
`
