import React, { useMemo } from "react"
import { Link } from "gatsby"

import { Container, Brand } from "./styles"

import Logo from "~/images/logo.png"
import LogoSedi from "~/images/partners/sedi.png"

export default function Navbar({ links }) {
  return (
    <Container>
      <div>
        <section>
          <Link
            to="https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/Regulamento_HACKATHON_Goias_Digital_2020_assinado_d73ec9a759.pdf"
            target="_blank"
          >
            Regulamento
          </Link>
          <Link to="#desafios">Desafios</Link>
        </section>

        <Brand>
          <img src={Logo} />
          {/* <img src={LogoSedi} /> */}
        </Brand>

        <section>
          <Link to="#organizacao">Organização</Link>
          <Link to="#mentores">Mentores</Link>
        </section>
      </div>
    </Container>
  )
}
