export default {
  primary: '#4E008E',
  secondary: '#CEDC00',

  yellow: '#FEDD00',

  title: '#CEDC00',
  text: '#FFFFFF',

  black: '#000000',
  white: '#FFFFFF',

  success: '#CEDC00',
  danger: '#e74c3c',
  warning: '#FEDD00',
};
