import styled from "styled-components"

import HeaderBackground from "~/images/header-background.png"

import { colors } from "~/styles"

export const Container = styled.div`
  background: url(${HeaderBackground}) no-repeat 50% top;
  min-height: 100vh;
  height: auto;

  @media (max-width: 768px) {
    min-height: inherit;
  }
`

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* height: calc(100vh - 200px); */
  padding: 0 40px;

  h3 {
    color: ${colors.secondary};
    font-size: 22px;
    font-weight: 700;
    margin-top: 80px;
    margin-bottom: 20px;
    letter-spacing: 0.5px
    text-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  }

  @media (max-width: 577px) {
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 100vh;
  }
`

export const Buttons = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;

  a {
    align-items: center;
    background: ${colors.yellow};
    border-radius: 6px;
    color: ${colors.primary};
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100px;
    margin-right: 90px;
    padding: 10px 20px;

    &:last-child {
      background: ${colors.secondary};
      margin-right: 0;
    }

    strong {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 8px;
      text-transform: uppercase;
      text-align: center;
    }

    span {
      font-size: 18px;
      font-weight: 300;
      text-align: center;
    }
  }

  @media (max-width: 768px) {
    a {
      margin-right: 40px;

      strong {
        font-size: 16px;
        margin-bottom: 5px;
      }

      span {
        font-size: 14px;
      }
    }
  }

  @media (max-width: 577px) {
    flex-direction: column;
    margin-top: 40px;
    width: 100%;

    a {
      margin: 0 0 20px;
      width: 100%;
    }
  }
`

export const CountdownDisplay = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: calc(100vh - 200px);
  width: 100%;

  > div {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 95px;

    &:last-child {
      margin-right: 0;
    }

    strong {
      color: ${colors.white};
      font-size: 100px;
      font-weight: 800;
    }

    span {
      color: ${colors.white};
      font-size: 35px;
      font-weight: 300;
    }
  }

  @media (max-width: 990px) {
    justify-content: space-between;

    > div {
      margin-right: 0;

      strong {
        font-size: 80px;
      }

      span {
        font-size: 32px;
      }
    }
  }

  @media (max-width: 768px) {
    justify-content: space-between;
    margin-top: 50px;

    > div {
      margin-right: 0;

      strong {
        font-size: 60px;
      }

      span {
        font-size: 22px;
      }
    }
  }

  @media (max-width: 577px) {
    margin-top: 20px;

    > div {
      margin-right: 0;

      strong {
        font-size: 30px;
      }

      span {
        font-size: 12px;
      }
    }
  }
`

export const Stage = styled.div`
  align-items: flex-end;
  background: ${colors.primary};
  border: 5px solid ${colors.primary};
  box-shadow: 0 40px 30px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  height: 0;
  max-width: 1200px;
  overflow: hidden;
  padding-bottom: 39.25%;
  position: relative;
  width: 100%;

  iframe {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
  }

  @media (max-width: 768px) {
    margin-top: 60px;
    min-height: calc(100vh - 200px);
  }
`
