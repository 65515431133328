import styled, { keyframes } from "styled-components"

import { colors } from "~/styles"

import { FaSpinner } from "react-icons/fa"

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;

  span { 
    color: ${colors.black};
    font-size: 14px;
    font-weight: 300;
  }

`

export const Spinner = styled(FaSpinner)`
  animation: ${rotate} 1.2s linear infinite;
  color: ${colors.primary};
  margin-right: 10px;
`
