import styled from "styled-components"

import { colors } from "~/styles"

export const Container = styled.div`
  /* align-items: center; */
  background: rgba(0, 0, 0, 0.2);
  bottom: 0;
  /* display: flex; */
  height: 100%;
  /* justify-content: center; */
  left: 0;
  padding: 40px 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;

  h3 {
    color: ${colors.secondary};
    font-size: 30px;
    font-weight: 600;
    margin: 0 auto 60px;
    text-align: center;
  }

  li {
    > div {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;
      padding: 0 30px;

      img {
        border-radius: 100%;
        height: 140px;
        margin-bottom: 20px;
        object-fit: cover;
        width: 140px;
      }

      strong {
        color: ${colors.white};
        font-size: 146x;
        font-weight: 600;
        margin-bottom: 5px;
        text-align: center;
      }

      span {
        color: ${colors.secondary};
        font-size: 14px;
        font-weight: 600;
        text-align: center;
      }

      ul {
        display: flex;
        flex-direction: row;
        list-style: none !important;
        padding: 0 !important;
        margin-top: 10px !important;

        li {
          margin: 0 3px;

          a {
            color: ${colors.white};
            font-size: 20px;
          }
        }
      }
    }
  }
`

export const People = styled.section`
  /* display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-top: 50px; */

  @media (max-width: 990px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 577px) {
    grid-template-columns: 1fr;
  }
`
