import React, { useMemo } from "react"
import { Link } from "gatsby"

import { Container } from "./styles"

import Logo from "~/images/logo-prte.png"

export default function Footer({ links }) {
  return (
    <Container>
      <div>
        <section>
          <strong>Quem Somos</strong>

          <Link to="/#organizacao">Organização</Link>
          <Link to="/#equipes">Equipes</Link>
          <Link to="/#mentores">Mentores</Link>
          <Link to="/#avaliadores">Avaliadores</Link>
          <Link to="/#apoio">Apoio</Link>
        </section>

        <section>
          <strong>O Evento</strong>

          <Link to="/#hackathon">Hackathon Goiás Digital 2020</Link>
          <Link
            to="https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/Regulamento_HACKATHON_Goias_Digital_2020_assinado_d73ec9a759.pdf"
            target="_blank"
          >
            Regulamento
          </Link>
          <Link to="/#desafios">Desafios</Link>
          <Link to="/#desafios">Central de Dados</Link>
        </section>

        <section>
          <strong>Cronograma</strong>

          <Link
            to="https://docs.google.com/forms/u/4/d/1sIspxspCuK0VABk5Ofj3vxFEZeIesh4OSTOw-C2vR-4/edit?usp=sharing"
            target="_blank"
          >
            Inscrições
          </Link>
          <Link to="/#cronograma">Cronograma</Link>
          <Link to="/#premiacao">Premiação</Link>
        </section>

        <section>
          <strong>Fale Conosco</strong>

          <Link to="http://quero.party/comunidadesomosprte" target="_blank">
            Discord
          </Link>
          <Link to="https://t.me/laraguerreiro" target="_blank">
            Imprensa
          </Link>
        </section>

        <section>
          <strong>Realização</strong>

          <Link to="https://prte.com.br/" target="_blank">
            <img src={Logo} />
          </Link>
        </section>
      </div>
    </Container>
  )
}
