import styled from "styled-components"

import { colors } from "~/styles"

export const Container = styled.div`
  display: flex;
  justify-content: center;
  /* padding-top: 30px; */
  width: 100%;

  > div {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 1200px;
    width: 100%;

    > section {
      display: flex;
      flex-direction: row;

      a {
        color: ${colors.white};
        font-size: 14px;
        font-weight: 700;
        margin-right: 40px;

        &:hover {
          color: ${colors.primary};
          transition: all 0.3s;
        }

        &:last-child {
          margin: 0;
        }
      }
    }

    @media (max-width: 990px) {
      > section {
        a {
          margin-right: 20px;
        }
      }
    }

    @media (max-width: 768px) {
      > section {
        display: none;
      }
    }
  }
`

export const Brand = styled.div`
  align-items: center;
  background: ${colors.white};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 40px;
  padding: 20px 40px;

  img {
    height: 100px;
    margin-right: 50px;

    &:last-child {
      height: 80px;
      margin-right: 0;
    }
  }

  @media (max-width: 990px) {
    padding: 10px 20px;

    img {
      height: 70px;

      &:last-child {
        height: 50px;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 10px 20px;

    img {
      height: 70px;

      &:last-child {
        height: 50px;
      }
    }
  }

  @media (max-width: 577px) {
    border-radius: 0;
    margin: 0;
    padding: 20px;
    width: 100%;
  }
`
