import React, { useMemo } from "react"

import { Link } from "gatsby"

import Navbar from "./Navbar"
import Countdown from "react-countdown"

import Slider from "./Slider"

import { Container, Content, Buttons, CountdownDisplay, Stage } from "./styles"

export default function Header({ links }) {
  return (
    <Container>
      <Navbar links={links} />

      <Content>
        <h3>Palco Hackathon Goiás Digital</h3>
        <Stage>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/APLzUXVUWhs?modestbranding=1&controls=0&rel=0&autoplay=1"
            frameborder="0"
            allowfullscreen
          ></iframe>
        </Stage>
      </Content>
    </Container>
  )
}
