import styled from "styled-components"

import CampusLogoImage from "~/images/logo-campus.png"
import SediLogoImage from "~/images/partners/sedi-white.png"
import PrteLogoImage from "~/images/logo-prte.png"

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  width: 100%;

  > div {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    padding: 40px;
    width: 100%;

    > a {
      text-align: center;

      img {
        max-width: 400px;
        margin-top: 60px;
      }
    }

    > section {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      margin-top: 40px;

      a {
        align-items: center;
        display: flex;
        justify-content: center;
        width: 100%;
      }

      img {
        margin-bottom: 40px;
        max-width: 240px;
        width: 100%;
      }
    }

    @media (max-width: 768px) {
      > section {
        grid-template-columns: 1fr 1fr;

        img {
          max-width: 180px;
        }
      }
    }

    @media (max-width: 577px) {
      > section {
        grid-template-columns: 1fr;

        img {
          max-width: 240px;
        }
      }
    }
  }
`

export const CampusLogo = styled.img.attrs({
  src: CampusLogoImage,
})`
  width: 240px;
`

export const SediLogo = styled.img.attrs({
  src: SediLogoImage,
})`
  width: 240px;
`

export const PrteLogo = styled.img.attrs({
  src: PrteLogoImage,
})`
  width: 240px;
`
