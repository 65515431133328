import styled from "styled-components"

import { colors } from "~/styles"

export const Container = styled.div`
  background: ${colors.primary};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  width: 100%;

  > main {
    h2 {
      color: ${colors.title};
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 30px;
    }

    h3 {
      color: ${colors.text};
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 20px;
    }

    p {
      color: ${colors.text};
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      margin-bottom: 30px;
    }

    ul {
      color: ${colors.text};
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      list-style: square;
      padding-left: 45px;
      margin: 10px 0 30px;

      li {
        margin-bottom: 12px;
      }
    }

    a {
      color: ${colors.secondary};
    }
  }

  @media (max-width: 578px) {
    > main {
      ul {
        padding-left: 25px;
      }
    }
  }
`
