import React, { useEffect, useState } from "react"

import api from "~/services/api"

import Layout from "../components/layout"
import SEO from "../components/seo"

import HackathonPage from "~/partials/hackathon"
import ChallengesPage from "~/partials/challenges"
import OrganizationPage from "~/partials/organization"
import TeamsPage from "~/partials/teams"
import MentorsPage from "~/partials/mentors"
import EvaluatorsPage from "~/partials/evaluators"
import SchedulerPage from "~/partials/scheduler"
import PartnersPage from "~/partials/partners"
import CommunitiesPage from "~/partials/communities"

export default function Index() {
  return (
    <Layout>
      <SEO title="Hackathon Goiás Digital 2020" />

      <SchedulerPage />
      <PartnersPage />
      <HackathonPage />
      <ChallengesPage />
      <OrganizationPage />
      <TeamsPage />
      <MentorsPage />
      <EvaluatorsPage />
      <CommunitiesPage />
    </Layout>
  )
}
