import styled from "styled-components"

import { colors } from "~/styles"

import SchedulerBackground from "~/images/scheduler-background.png"

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  /* min-height: 100vh; */
  margin-bottom: 40px;
  width: 100%;

  > div {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    padding: 40px;
    width: 100%;

    > section {
      background: ${colors.white} url(${SchedulerBackground}) no-repeat 100%
        100%;
      background-size: 100%;
      border-radius: 10px;
      padding: 40px;

      table {
        thead {
          tr {
            th {
              color: ${colors.primary};
              font-size: 16px;
              font-weight: 700;
              padding: 10px;
              text-align: left;
              text-transform: uppercase;
              vertical-align: top;
            }
          }
        }

        tbody {
          tr {
            td {
              color: ${colors.black};
              font-size: 14px;
              font-weight: 400;
              padding: 10px;
              vertical-align: top;
            }
          }
        }
      }
    }

    @media (max-width: 577px) {
      > section {
        padding: 10px;

        table {
          thead {
            tr {
              th {
                font-size: 12px;
                padding: 5px;

                &:last-child {
                  display: none;
                }
              }
            }
          }

          tbody {
            tr {
              td {
                font-size: 12px;
                padding: 5px;

                &:last-child {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
`
