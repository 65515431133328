import styled from "styled-components"

import { colors } from "~/styles"

export const Container = styled.div`
  background: ${colors.primary};
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* min-height: 100vh; */
  width: 100%;

  > div {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    padding: 40px;
    width: 100%;

    h2 {
      margin-bottom: 40px;
    }
  }
`

export const Companies = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;

  a {
    align-items: center;
    background: ${colors.white};
    border-radius: 10px;
    display: flex;
    justify-content: center;
    margin: 0 5px 10px;
    padding: 20px 10px;
  }

  img {
    display: flex;
    margin: 0 auto;
    max-height: 90px;
    object-fit: contain;
    text-align: center;
    width: 80%;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 577px) {
    grid-template-columns: 1fr;

    img {
      text-align: left;
      margin: 0 0 40px;
      width: auto;
    }
  }
`
