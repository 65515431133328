import React from "react"
import { Link } from "gatsby"

import { Container, Companies } from "./styles"

import CampoLab from "~/images/partners/campo-lab.png"
import Fapeg from "~/images/partners/fapeg.png"
import Sedi from "~/images/partners/sedi.png"
import Ceia from "~/images/partners/ceia.png"
import Fieg from "~/images/partners/fieg.png"
import Sebrae from "~/images/partners/sebrae.png"

const partners = [
  {
    name: "Campo Lab",
    link: "https://sistemafaeg.com.br/faeg/noticias/campo-lab",
    logo: CampoLab,
  },
  {
    name: "Fapeg",
    link: "http://www.fapeg.go.gov.br/",
    logo: Fapeg,
  },
  {
    name: "SEDI Goiás",
    link: "https://www.desenvolvimento.go.gov.br/",
    logo: Sedi,
  },
  {
    name: "Centro de Excelência em IA",
    link: "https://www.desenvolvimento.go.gov.br/",
    logo: Sedi,
  },
  {
    name: "Fieg",
    link: "https://www.desenvolvimento.go.gov.br/",
    logo: Fieg,
  },
  {
    name: "Sebrae",
    link: "https://www.desenvolvimento.go.gov.br/",
    logo: Sebrae,
  },
]

const communities = [
  // {
  //   name: "Campos Lab",
  //   link: "",
  //   logo: CampoLab,
  //   width: 443,
  // },
]

export default function Supporters() {
  return (
    !!communities.length && (
      <Container id="apoio">
        <div>
          <h2>Comunidades</h2>

          <Companies>
            {communities.map((community, index) => (
              <Link
                href={community.link}
                target="_blank"
                key={`community-${String(index)}`}
              >
                <img
                  src={community.logo}
                  alt={community.name}
                  // style={{ width: community.width }}
                />
              </Link>
            ))}
          </Companies>
        </div>
      </Container>
    )
  )
}
