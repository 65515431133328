import { create } from 'axios';

const api = create({
  baseURL: process.env.GATSBY_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  responseType: 'json',
});

export default api;
