import React, { useMemo } from "react"
import { Link } from "gatsby"

import Markdown from "react-markdown"

import { Container, CampusLogo, PrteLogo, SediLogo } from "./styles"

export default function Organization() {
  return (
    <Container id="organizacao">
      <div>
        <h2>Organização</h2>
        <Markdown>
          {`A promoção e organização da Hackathon Goiás - Desafios com Dados
          Abertos é da Campus Party Brasil, em conjunto com o Estado de Goiás,
          por meio da Secretaria de Estado de Desenvolvimento e Inovação, com a
          coordenação da PRTE - Tecnologia e Soluções LTDA -ME.\n`}
        </Markdown>

        <section>
          <Link to="https://goias-digital.campus-party.org/" target="_blank">
            <CampusLogo />
          </Link>

          <Link to="https://prte.com.br/" target="_blank">
            <PrteLogo />
          </Link>

          <Link to="https://www.desenvolvimento.go.gov.br/" target="_blank">
            <SediLogo />
          </Link>
        </section>
      </div>
    </Container>
  )
}
