import React, { useState, useEffect, useMemo } from "react"
import { Link } from "gatsby"

import api from "~/services/api"

import Markdown from "react-markdown"

import { FaLinkedin, FaGithub } from "react-icons/fa"

import { Container, People } from "./styles"

const mentors = [
  {
    id: 29,
    avatar: {
      id: 32,
      name: "alexandra",
      alternativeText: "",
      caption: "",
      width: 356,
      height: 355,
      formats: {
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_alexandra_a6c7e57bb6.jpeg",
          hash: "thumbnail_alexandra_a6c7e57bb6",
          mime: "image/jpeg",
          path: null,
          size: 3.82,
          width: 156,
          height: 156,
        },
      },
      hash: "alexandra_a6c7e57bb6",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 14.98,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/alexandra_a6c7e57bb6.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-06T19:17:24.033Z",
      updated_at: "2020-07-06T19:17:24.033Z",
    },
    name: "Alexandra Vitorio",
    company: "Serpro - Analista de desenvolvimento",
    linkedin: "https://www.linkedin.com/in/alexandra-vitorio-960897181/",
    github: null,
    created_at: "2020-07-06T19:17:46.786Z",
    updated_at: "2020-07-06T19:17:46.786Z",
  },
  {
    id: 1,
    avatar: {
      id: 1,
      name: "9993834",
      alternativeText: "",
      caption: "",
      width: 460,
      height: 460,
      formats: {
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_9993834_c8644c1acb.jpeg",
          hash: "thumbnail_9993834_c8644c1acb",
          mime: "image/jpeg",
          path: null,
          size: 4.95,
          width: 156,
          height: 156,
        },
      },
      hash: "9993834_c8644c1acb",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 21.35,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/9993834_c8644c1acb.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-01T14:55:32.573Z",
      updated_at: "2020-07-01T14:55:32.573Z",
    },
    name: "Gabriel Buzzi Venturi",
    company: "CIO na PRTE",
    linkedin: "https://www.linkedin.com/in/gabriel-buzzi-venturi-71508099/",
    github: "https://github.com/gabrielbuzziv",
    created_at: "2020-06-26T20:54:13.288Z",
    updated_at: "2020-07-03T19:32:12.767Z",
  },
  {
    id: 4,
    avatar: {
      id: 6,
      name: "ALEXFOESSEL",
      alternativeText: "",
      caption: "",
      width: 318,
      height: 381,
      formats: {
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_ALEXFOESSEL_e9c3a44cbe.jpeg",
          hash: "thumbnail_ALEXFOESSEL_e9c3a44cbe",
          mime: "image/jpeg",
          path: null,
          size: 3.79,
          width: 130,
          height: 156,
        },
      },
      hash: "ALEXFOESSEL_e9c3a44cbe",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 12.63,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/ALEXFOESSEL_e9c3a44cbe.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-03T19:32:49.541Z",
      updated_at: "2020-07-03T19:32:49.541Z",
    },
    name: "Alex Foessel",
    company:
      "Consultor | Estratégia de Inovação Tecnológica | Robótica e Automação | Inovacao no Agro",
    linkedin: "https://www.linkedin.com/in/afoessel/",
    github: null,
    created_at: "2020-07-03T19:33:40.245Z",
    updated_at: "2020-07-03T19:33:40.245Z",
  },
  {
    id: 5,
    avatar: {
      id: 7,
      name: "Rodrigo",
      alternativeText: "",
      caption: "",
      width: 685,
      height: 760,
      formats: {
        small: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/small_Rodrigo_3fba78c7e2.jpeg",
          hash: "small_Rodrigo_3fba78c7e2",
          mime: "image/jpeg",
          path: null,
          size: 33.92,
          width: 451,
          height: 500,
        },
        medium: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/medium_Rodrigo_3fba78c7e2.jpeg",
          hash: "medium_Rodrigo_3fba78c7e2",
          mime: "image/jpeg",
          path: null,
          size: 64.08,
          width: 676,
          height: 750,
        },
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_Rodrigo_3fba78c7e2.jpeg",
          hash: "thumbnail_Rodrigo_3fba78c7e2",
          mime: "image/jpeg",
          path: null,
          size: 5.63,
          width: 141,
          height: 156,
        },
      },
      hash: "Rodrigo_3fba78c7e2",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 66.98,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/Rodrigo_3fba78c7e2.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-03T19:34:24.678Z",
      updated_at: "2020-07-03T19:34:24.678Z",
    },
    name: "Rodrigo Spillere",
    company: "Gerente de Inovação Leroy Merlin Brasil",
    linkedin: "https://www.linkedin.com/in/rodrigospillere/",
    github: null,
    created_at: "2020-07-03T19:35:19.660Z",
    updated_at: "2020-07-03T19:35:19.660Z",
  },
  {
    id: 7,
    avatar: {
      id: 9,
      name: "LucasGabriel",
      alternativeText: "",
      caption: "",
      width: 965,
      height: 1280,
      formats: {
        large: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/large_Lucas_Gabriel_ae0872be1d.jpeg",
          hash: "large_Lucas_Gabriel_ae0872be1d",
          mime: "image/jpeg",
          path: null,
          size: 101.23,
          width: 754,
          height: 1000,
        },
        small: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/small_Lucas_Gabriel_ae0872be1d.jpeg",
          hash: "small_Lucas_Gabriel_ae0872be1d",
          mime: "image/jpeg",
          path: null,
          size: 28.03,
          width: 377,
          height: 500,
        },
        medium: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/medium_Lucas_Gabriel_ae0872be1d.jpeg",
          hash: "medium_Lucas_Gabriel_ae0872be1d",
          mime: "image/jpeg",
          path: null,
          size: 58.77,
          width: 565,
          height: 750,
        },
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_Lucas_Gabriel_ae0872be1d.jpeg",
          hash: "thumbnail_Lucas_Gabriel_ae0872be1d",
          mime: "image/jpeg",
          path: null,
          size: 4.24,
          width: 118,
          height: 156,
        },
      },
      hash: "Lucas_Gabriel_ae0872be1d",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 160.21,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/Lucas_Gabriel_ae0872be1d.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-03T20:29:25.113Z",
      updated_at: "2020-07-03T20:29:25.113Z",
    },
    name: "Lucas Gabriel",
    company: "PRTE - Desenvolvedor de softwares | Front-end/mobile",
    linkedin: "https://www.linkedin.com/in/lg1992/",
    github: "https://github.com/lucasGabrielDeAA",
    created_at: "2020-07-03T20:30:13.241Z",
    updated_at: "2020-07-03T20:30:49.248Z",
  },
  {
    id: 8,
    avatar: {
      id: 10,
      name: "JAQUELINEALVES",
      alternativeText: "",
      caption: "",
      width: 800,
      height: 800,
      formats: {
        small: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/small_JAQUELINEALVES_8d98b1d1b1.jpeg",
          hash: "small_JAQUELINEALVES_8d98b1d1b1",
          mime: "image/jpeg",
          path: null,
          size: 28.42,
          width: 500,
          height: 500,
        },
        medium: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/medium_JAQUELINEALVES_8d98b1d1b1.jpeg",
          hash: "medium_JAQUELINEALVES_8d98b1d1b1",
          mime: "image/jpeg",
          path: null,
          size: 53.55,
          width: 750,
          height: 750,
        },
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_JAQUELINEALVES_8d98b1d1b1.jpeg",
          hash: "thumbnail_JAQUELINEALVES_8d98b1d1b1",
          mime: "image/jpeg",
          path: null,
          size: 4.66,
          width: 156,
          height: 156,
        },
      },
      hash: "JAQUELINEALVES_8d98b1d1b1",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 60.32,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/JAQUELINEALVES_8d98b1d1b1.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-03T20:59:20.600Z",
      updated_at: "2020-07-03T20:59:20.600Z",
    },
    name: "Jaqueline Alves Ribeiro",
    company:
      "Palestrante | Internet das Coisas (IOT), Inovação, Tecnologia Assistiva e Engenharia Biomédica",
    linkedin: "https://www.linkedin.com/in/jaqueline-ribeiro-a78a6082/",
    github: null,
    created_at: "2020-07-03T20:59:56.662Z",
    updated_at: "2020-07-03T21:00:55.587Z",
  },
  {
    id: 9,
    avatar: {
      id: 11,
      name: "Luis Machado",
      alternativeText: "",
      caption: "",
      width: 500,
      height: 500,
      formats: {
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_Luis_Machado_1f1789a38f.jpeg",
          hash: "thumbnail_Luis_Machado_1f1789a38f",
          mime: "image/jpeg",
          path: null,
          size: 3.97,
          width: 156,
          height: 156,
        },
      },
      hash: "Luis_Machado_1f1789a38f",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 27.22,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/Luis_Machado_1f1789a38f.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-06T13:02:35.557Z",
      updated_at: "2020-07-06T13:02:35.557Z",
    },
    name: "Luiz Machado",
    company: "Cloud Solutions Architect | DevSecOps | Security | RPA",
    linkedin: "https://www.linkedin.com/in/luizmachadoit/",
    github: "https://github.com/crypto-br/",
    created_at: "2020-07-06T13:03:05.611Z",
    updated_at: "2020-07-06T13:03:05.611Z",
  },
  {
    id: 10,
    avatar: {
      id: 12,
      name: "ELIEZER",
      alternativeText: "",
      caption: "",
      width: 800,
      height: 800,
      formats: {
        small: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/small_ELIEZER_edd8ecbb8e.jpeg",
          hash: "small_ELIEZER_edd8ecbb8e",
          mime: "image/jpeg",
          path: null,
          size: 37.76,
          width: 500,
          height: 500,
        },
        medium: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/medium_ELIEZER_edd8ecbb8e.jpeg",
          hash: "medium_ELIEZER_edd8ecbb8e",
          mime: "image/jpeg",
          path: null,
          size: 71.07,
          width: 750,
          height: 750,
        },
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_ELIEZER_edd8ecbb8e.jpeg",
          hash: "thumbnail_ELIEZER_edd8ecbb8e",
          mime: "image/jpeg",
          path: null,
          size: 5.88,
          width: 156,
          height: 156,
        },
      },
      hash: "ELIEZER_edd8ecbb8e",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 79.81,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/ELIEZER_edd8ecbb8e.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-06T13:13:40.494Z",
      updated_at: "2020-07-06T13:13:40.494Z",
    },
    name: "Eliezer Trajano",
    company: "Head Criação de Novos Produtos | Serpro",
    linkedin: "https://www.linkedin.com/in/eliezer-trajano/",
    github: null,
    created_at: "2020-07-06T13:14:17.645Z",
    updated_at: "2020-07-06T13:14:17.645Z",
  },
  {
    id: 11,
    avatar: {
      id: 13,
      name: "HUGOSEABRA",
      alternativeText: "",
      caption: "",
      width: 800,
      height: 800,
      formats: {
        small: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/small_HUGOSEABRA_db109e8e57.jpeg",
          hash: "small_HUGOSEABRA_db109e8e57",
          mime: "image/jpeg",
          path: null,
          size: 35.61,
          width: 500,
          height: 500,
        },
        medium: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/medium_HUGOSEABRA_db109e8e57.jpeg",
          hash: "medium_HUGOSEABRA_db109e8e57",
          mime: "image/jpeg",
          path: null,
          size: 75.04,
          width: 750,
          height: 750,
        },
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_HUGOSEABRA_db109e8e57.jpeg",
          hash: "thumbnail_HUGOSEABRA_db109e8e57",
          mime: "image/jpeg",
          path: null,
          size: 5.45,
          width: 156,
          height: 156,
        },
      },
      hash: "HUGOSEABRA_db109e8e57",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 86.98,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/HUGOSEABRA_db109e8e57.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-06T13:25:06.571Z",
      updated_at: "2020-07-06T13:25:06.571Z",
    },
    name: "Hugo Seabra",
    company: "Senior Software Engineer at Minespider | CTO da Congressy",
    linkedin: "https://www.linkedin.com/in/hugoseabra/",
    github: "https://github.com/hugoseabra",
    created_at: "2020-07-06T13:25:47.691Z",
    updated_at: "2020-07-06T13:40:48.382Z",
  },
  {
    id: 12,
    avatar: {
      id: 14,
      name: "VINICIUSCATAFESTA",
      alternativeText: "",
      caption: "",
      width: 800,
      height: 800,
      formats: {
        small: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/small_VINICIUSCATAFESTA_77b946374a.jpeg",
          hash: "small_VINICIUSCATAFESTA_77b946374a",
          mime: "image/jpeg",
          path: null,
          size: 20.81,
          width: 500,
          height: 500,
        },
        medium: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/medium_VINICIUSCATAFESTA_77b946374a.jpeg",
          hash: "medium_VINICIUSCATAFESTA_77b946374a",
          mime: "image/jpeg",
          path: null,
          size: 41.13,
          width: 750,
          height: 750,
        },
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_VINICIUSCATAFESTA_77b946374a.jpeg",
          hash: "thumbnail_VINICIUSCATAFESTA_77b946374a",
          mime: "image/jpeg",
          path: null,
          size: 3.63,
          width: 156,
          height: 156,
        },
      },
      hash: "VINICIUSCATAFESTA_77b946374a",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 46.78,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/VINICIUSCATAFESTA_77b946374a.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-06T13:42:43.518Z",
      updated_at: "2020-07-06T13:42:43.518Z",
    },
    name: "Vinicius Catafesta",
    company:
      "PRTE - Desenvolvedor Full-stack JavaScript | NodeJS | ReactJS | React-Native",
    linkedin:
      "https://www.linkedin.com/in/vin%C3%ADcius-catafesta-francisco-096393141/",
    github: "https://github.com/vinicfrancisco",
    created_at: "2020-07-06T13:44:07.244Z",
    updated_at: "2020-07-06T13:44:07.244Z",
  },
  {
    id: 13,
    avatar: {
      id: 15,
      name: "FERNANDAFERREIRA",
      alternativeText: "",
      caption: "",
      width: 640,
      height: 640,
      formats: {
        small: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/small_FERNANDAFERREIRA_6dd5e27c17.jpeg",
          hash: "small_FERNANDAFERREIRA_6dd5e27c17",
          mime: "image/jpeg",
          path: null,
          size: 44.76,
          width: 500,
          height: 500,
        },
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_FERNANDAFERREIRA_6dd5e27c17.jpeg",
          hash: "thumbnail_FERNANDAFERREIRA_6dd5e27c17",
          mime: "image/jpeg",
          path: null,
          size: 6.11,
          width: 156,
          height: 156,
        },
      },
      hash: "FERNANDAFERREIRA_6dd5e27c17",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 62.58,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/FERNANDAFERREIRA_6dd5e27c17.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-06T13:49:32.057Z",
      updated_at: "2020-07-06T13:49:32.057Z",
    },
    name: "Fernanda Ferreira",
    company: "Desenvolvedora .NET C#  | PRTE - DevSecOps Junior",
    linkedin: "https://www.linkedin.com/in/ferreirafernandar/",
    github: "https://github.com/ferreirafernandar",
    created_at: "2020-07-06T13:50:31.662Z",
    updated_at: "2020-07-06T13:50:31.662Z",
  },
  {
    id: 14,
    avatar: {
      id: 16,
      name: "NIVALDONETO",
      alternativeText: "",
      caption: "",
      width: 500,
      height: 500,
      formats: {
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_NIVALDONETO_33f0e36375.jpeg",
          hash: "thumbnail_NIVALDONETO_33f0e36375",
          mime: "image/jpeg",
          path: null,
          size: 5.71,
          width: 156,
          height: 156,
        },
      },
      hash: "NIVALDONETO_33f0e36375",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 36.41,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/NIVALDONETO_33f0e36375.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-06T14:07:53.981Z",
      updated_at: "2020-07-06T14:07:53.981Z",
    },
    name: "Nivaldo Neto",
    company: "PRTE | Desenvolvedor Front-End / UI Designer",
    linkedin: "https://www.linkedin.com/in/nivaldojsneto/",
    github: "https://github.com/nivaldojsneto",
    created_at: "2020-07-06T14:08:32.268Z",
    updated_at: "2020-07-06T14:08:32.268Z",
  },
  {
    id: 30,
    avatar: {
      id: 33,
      name: "VANESSA",
      alternativeText: "",
      caption: "",
      width: 800,
      height: 800,
      formats: {
        small: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/small_VANESSA_f4c5698dbb.jpeg",
          hash: "small_VANESSA_f4c5698dbb",
          mime: "image/jpeg",
          path: null,
          size: 33.57,
          width: 500,
          height: 500,
        },
        medium: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/medium_VANESSA_f4c5698dbb.jpeg",
          hash: "medium_VANESSA_f4c5698dbb",
          mime: "image/jpeg",
          path: null,
          size: 64.51,
          width: 750,
          height: 750,
        },
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_VANESSA_f4c5698dbb.jpeg",
          hash: "thumbnail_VANESSA_f4c5698dbb",
          mime: "image/jpeg",
          path: null,
          size: 5.63,
          width: 156,
          height: 156,
        },
      },
      hash: "VANESSA_f4c5698dbb",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 74.11,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/VANESSA_f4c5698dbb.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-06T19:22:21.830Z",
      updated_at: "2020-07-06T19:22:21.830Z",
    },
    name: "Vanessa Camargo",
    company: "Business Developer no AAA Inovação",
    linkedin: "https://www.linkedin.com/in/vanessaxcamargo/",
    github: null,
    created_at: "2020-07-06T19:22:41.574Z",
    updated_at: "2020-07-06T19:22:41.574Z",
  },
  {
    id: 3,
    avatar: {
      id: 17,
      name: "WhatsApp Image 2020-07-06 at 12.02.15",
      alternativeText: "",
      caption: "",
      width: 484,
      height: 586,
      formats: {
        small: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/small_Whats_App_Image_2020_07_06_at_12_02_15_84e5627ec1.jpeg",
          hash: "small_Whats_App_Image_2020_07_06_at_12_02_15_84e5627ec1",
          mime: "image/jpeg",
          path: null,
          size: 31.04,
          width: 413,
          height: 500,
        },
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_Whats_App_Image_2020_07_06_at_12_02_15_84e5627ec1.jpeg",
          hash: "thumbnail_Whats_App_Image_2020_07_06_at_12_02_15_84e5627ec1",
          mime: "image/jpeg",
          path: null,
          size: 4.39,
          width: 129,
          height: 156,
        },
      },
      hash: "Whats_App_Image_2020_07_06_at_12_02_15_84e5627ec1",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 40.6,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/Whats_App_Image_2020_07_06_at_12_02_15_84e5627ec1.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-06T15:04:26.030Z",
      updated_at: "2020-07-06T15:04:26.030Z",
    },
    name: "Giovanni Montoneri",
    company:
      "CEO, Palestrante, Conselheiro em Transformação Digital e Mentor de Start Ups",
    linkedin: "https://www.linkedin.com/in/giovannimontoneri/",
    github: null,
    created_at: "2020-07-03T19:32:11.870Z",
    updated_at: "2020-07-06T16:45:42.976Z",
  },
  {
    id: 15,
    avatar: {
      id: 18,
      name: "Haihani",
      alternativeText: "",
      caption: "",
      width: 320,
      height: 320,
      formats: {
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_Haihani_f397f6adbb.jpeg",
          hash: "thumbnail_Haihani_f397f6adbb",
          mime: "image/jpeg",
          path: null,
          size: 4.92,
          width: 156,
          height: 156,
        },
      },
      hash: "Haihani_f397f6adbb",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 14.06,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/Haihani_f397f6adbb.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-06T16:57:56.462Z",
      updated_at: "2020-07-06T16:57:56.462Z",
    },
    name: "Haihani Silva Passos",
    company: "Professora | Pesquisadora | Gestora IF Goiano",
    linkedin: "https://www.linkedin.com/in/haihanipassos/",
    github: null,
    created_at: "2020-07-06T16:58:34.707Z",
    updated_at: "2020-07-06T16:58:34.707Z",
  },
  {
    id: 17,
    avatar: {
      id: 20,
      name: "fabiano",
      alternativeText: "",
      caption: "",
      width: 800,
      height: 800,
      formats: {
        small: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/small_fabiano_2d8801e2b2.jpeg",
          hash: "small_fabiano_2d8801e2b2",
          mime: "image/jpeg",
          path: null,
          size: 35.43,
          width: 500,
          height: 500,
        },
        medium: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/medium_fabiano_2d8801e2b2.jpeg",
          hash: "medium_fabiano_2d8801e2b2",
          mime: "image/jpeg",
          path: null,
          size: 67.66,
          width: 750,
          height: 750,
        },
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_fabiano_2d8801e2b2.jpeg",
          hash: "thumbnail_fabiano_2d8801e2b2",
          mime: "image/jpeg",
          path: null,
          size: 5.96,
          width: 156,
          height: 156,
        },
      },
      hash: "fabiano_2d8801e2b2",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 76.4,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/fabiano_2d8801e2b2.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-06T17:32:04.518Z",
      updated_at: "2020-07-06T17:32:04.518Z",
    },
    name: "Fabiano Nagamatsu",
    company:
      "Inova UNIGRAN | StartupMS | Inovativa Brasil | SharkTank BR | Mentores do Brasil | Confraria do Empreendedor | Techstars",
    linkedin: "https://www.linkedin.com/in/fabianonagamatsu/",
    github: null,
    created_at: "2020-07-06T17:32:08.170Z",
    updated_at: "2020-07-06T17:32:08.170Z",
  },
  {
    id: 18,
    avatar: {
      id: 21,
      name: "Alberto",
      alternativeText: "",
      caption: "",
      width: 800,
      height: 800,
      formats: {
        small: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/small_Alberto_068e58d07f.jpeg",
          hash: "small_Alberto_068e58d07f",
          mime: "image/jpeg",
          path: null,
          size: 33.02,
          width: 500,
          height: 500,
        },
        medium: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/medium_Alberto_068e58d07f.jpeg",
          hash: "medium_Alberto_068e58d07f",
          mime: "image/jpeg",
          path: null,
          size: 62.62,
          width: 750,
          height: 750,
        },
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_Alberto_068e58d07f.jpeg",
          hash: "thumbnail_Alberto_068e58d07f",
          mime: "image/jpeg",
          path: null,
          size: 5.39,
          width: 156,
          height: 156,
        },
      },
      hash: "Alberto_068e58d07f",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 70.18,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/Alberto_068e58d07f.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-06T17:37:05.830Z",
      updated_at: "2020-07-06T17:37:05.830Z",
    },
    name: "Alberto Barbo",
    company: "Co Founder na Banken Digital Asset Proptech",
    linkedin: "https://www.linkedin.com/in/19730602/",
    github: null,
    created_at: "2020-07-06T17:37:09.817Z",
    updated_at: "2020-07-06T17:37:09.817Z",
  },
  {
    id: 19,
    avatar: {
      id: 22,
      name: "brunomorais",
      alternativeText: "",
      caption: "",
      width: 800,
      height: 800,
      formats: {
        small: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/small_brunomorais_ca87055ea6.jpeg",
          hash: "small_brunomorais_ca87055ea6",
          mime: "image/jpeg",
          path: null,
          size: 48.08,
          width: 500,
          height: 500,
        },
        medium: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/medium_brunomorais_ca87055ea6.jpeg",
          hash: "medium_brunomorais_ca87055ea6",
          mime: "image/jpeg",
          path: null,
          size: 87.77,
          width: 750,
          height: 750,
        },
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_brunomorais_ca87055ea6.jpeg",
          hash: "thumbnail_brunomorais_ca87055ea6",
          mime: "image/jpeg",
          path: null,
          size: 6.68,
          width: 156,
          height: 156,
        },
      },
      hash: "brunomorais_ca87055ea6",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 98.96,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/brunomorais_ca87055ea6.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-06T17:59:28.257Z",
      updated_at: "2020-07-06T17:59:28.257Z",
    },
    name: "Bruno Santos Morais",
    company:
      "Especialista em Desenvolvimento Mobile e Desenvolvedor Web FullStack",
    linkedin: "https://www.linkedin.com/in/brunomoraisti/",
    github: "https://github.com/brunomoraisti",
    created_at: "2020-07-06T17:59:58.772Z",
    updated_at: "2020-07-06T17:59:58.772Z",
  },
  {
    id: 20,
    avatar: {
      id: 23,
      name: "Cleber",
      alternativeText: "",
      caption: "",
      width: 627,
      height: 638,
      formats: {
        small: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/small_Cleber_91b2d8d68c.jpeg",
          hash: "small_Cleber_91b2d8d68c",
          mime: "image/jpeg",
          path: null,
          size: 57.31,
          width: 491,
          height: 500,
        },
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_Cleber_91b2d8d68c.jpeg",
          hash: "thumbnail_Cleber_91b2d8d68c",
          mime: "image/jpeg",
          path: null,
          size: 7.75,
          width: 153,
          height: 156,
        },
      },
      hash: "Cleber_91b2d8d68c",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 81.91,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/Cleber_91b2d8d68c.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-06T18:05:11.108Z",
      updated_at: "2020-07-06T18:05:11.108Z",
    },
    name: "Cleber Porfiro Muniz",
    company: "Especialista em marcas, professor e empreendedor",
    linkedin: "https://www.linkedin.com/in/cleber-muniz-a522a738/",
    github: null,
    created_at: "2020-07-06T18:05:27.969Z",
    updated_at: "2020-07-06T18:05:27.969Z",
  },
  {
    id: 21,
    avatar: {
      id: 24,
      name: "gisely",
      alternativeText: "",
      caption: "",
      width: 1439,
      height: 1440,
      formats: {
        large: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/large_gisely_6b9444f8c4.jpeg",
          hash: "large_gisely_6b9444f8c4",
          mime: "image/jpeg",
          path: null,
          size: 120.63,
          width: 999,
          height: 1000,
        },
        small: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/small_gisely_6b9444f8c4.jpeg",
          hash: "small_gisely_6b9444f8c4",
          mime: "image/jpeg",
          path: null,
          size: 32.5,
          width: 500,
          height: 500,
        },
        medium: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/medium_gisely_6b9444f8c4.jpeg",
          hash: "medium_gisely_6b9444f8c4",
          mime: "image/jpeg",
          path: null,
          size: 67.72,
          width: 749,
          height: 750,
        },
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_gisely_6b9444f8c4.jpeg",
          hash: "thumbnail_gisely_6b9444f8c4",
          mime: "image/jpeg",
          path: null,
          size: 5.22,
          width: 156,
          height: 156,
        },
      },
      hash: "gisely_6b9444f8c4",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 246.28,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/gisely_6b9444f8c4.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-06T18:09:38.791Z",
      updated_at: "2020-07-06T18:09:38.791Z",
    },
    name: "Gisely Maciel",
    company:
      "Projetista de Experiência do Usuário (UX), Analista de Requisitos, Projetista de Teste, Analista de Métricas em SERPRO ",
    linkedin: "https://www.linkedin.com/in/gisely-maciel-18475455/",
    github: null,
    created_at: "2020-07-06T18:09:56.228Z",
    updated_at: "2020-07-06T18:09:56.228Z",
  },
  {
    id: 22,
    avatar: {
      id: 25,
      name: "igor",
      alternativeText: "",
      caption: "",
      width: 640,
      height: 640,
      formats: {
        small: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/small_igor_ca3103264e.jpeg",
          hash: "small_igor_ca3103264e",
          mime: "image/jpeg",
          path: null,
          size: 35.42,
          width: 500,
          height: 500,
        },
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_igor_ca3103264e.jpeg",
          hash: "thumbnail_igor_ca3103264e",
          mime: "image/jpeg",
          path: null,
          size: 5.45,
          width: 156,
          height: 156,
        },
      },
      hash: "igor_ca3103264e",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 50.57,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/igor_ca3103264e.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-06T18:20:02.634Z",
      updated_at: "2020-07-06T18:20:02.634Z",
    },
    name: "Igor Arnaldo de Alencar",
    company: "Gamificação e Desenvolvimento de Jogos",
    linkedin: "https://www.linkedin.com/in/igor-arnaldo-de-alencar-5219b014b/",
    github: null,
    created_at: "2020-07-06T18:20:34.421Z",
    updated_at: "2020-07-06T18:20:34.421Z",
  },
  {
    id: 23,
    avatar: {
      id: 26,
      name: "JuanSebastian",
      alternativeText: "",
      caption: "",
      width: 500,
      height: 500,
      formats: {
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_Juan_Sebastian_7076fa1d46.jpeg",
          hash: "thumbnail_Juan_Sebastian_7076fa1d46",
          mime: "image/jpeg",
          path: null,
          size: 7.34,
          width: 156,
          height: 156,
        },
      },
      hash: "Juan_Sebastian_7076fa1d46",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 66.07,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/Juan_Sebastian_7076fa1d46.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-06T18:31:39.656Z",
      updated_at: "2020-07-06T18:31:39.656Z",
    },
    name: "Juan Sebastian Toquica",
    company:
      "Departamento de Engenharia Mecânica - UNB | Pesquisas em Engenharia Mecatrônica",
    linkedin: "https://www.linkedin.com/in/jstoquica/",
    github: null,
    created_at: "2020-07-06T18:32:03.218Z",
    updated_at: "2020-07-06T18:32:03.218Z",
  },
  {
    id: 24,
    avatar: {
      id: 27,
      name: "leornardopereira",
      alternativeText: "",
      caption: "",
      width: 336,
      height: 336,
      formats: {
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_leornardopereira_70c7953907.jpeg",
          hash: "thumbnail_leornardopereira_70c7953907",
          mime: "image/jpeg",
          path: null,
          size: 3.58,
          width: 156,
          height: 156,
        },
      },
      hash: "leornardopereira_70c7953907",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 11,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/leornardopereira_70c7953907.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-06T18:37:42.830Z",
      updated_at: "2020-07-06T18:37:42.830Z",
    },
    name: "Leonardo Pereira",
    company:
      "Coordenador de Infraestrutura de TI - Fujioka Eletro Imagem S.A | Professor de Análise e Desenvolvimento de Software - Senac GO",
    linkedin: "https://www.linkedin.com/in/leonardo-pereira-49065b16/",
    github: "https://github.com/LeoMorais/",
    created_at: "2020-07-06T18:38:16.464Z",
    updated_at: "2020-07-06T18:38:16.464Z",
  },
  {
    id: 25,
    avatar: {
      id: 28,
      name: "MARIANE",
      alternativeText: "",
      caption: "",
      width: 600,
      height: 600,
      formats: {
        small: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/small_MARIANE_a223fe10eb.jpeg",
          hash: "small_MARIANE_a223fe10eb",
          mime: "image/jpeg",
          path: null,
          size: 28.96,
          width: 500,
          height: 500,
        },
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_MARIANE_a223fe10eb.jpeg",
          hash: "thumbnail_MARIANE_a223fe10eb",
          mime: "image/jpeg",
          path: null,
          size: 4.72,
          width: 156,
          height: 156,
        },
      },
      hash: "MARIANE_a223fe10eb",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 40.09,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/MARIANE_a223fe10eb.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-06T18:42:53.961Z",
      updated_at: "2020-07-06T18:42:53.961Z",
    },
    name: "Marianne Lins",
    company: "Founder & CEO - Flicks Marketing",
    linkedin: "https://www.linkedin.com/in/mariannemlins/",
    github: null,
    created_at: "2020-07-06T18:43:22.541Z",
    updated_at: "2020-07-06T18:43:22.541Z",
  },
  {
    id: 27,
    avatar: {
      id: 30,
      name: "Roberta",
      alternativeText: "",
      caption: "",
      width: 800,
      height: 800,
      formats: {
        small: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/small_Roberta_88deb7f916.jpeg",
          hash: "small_Roberta_88deb7f916",
          mime: "image/jpeg",
          path: null,
          size: 35.8,
          width: 500,
          height: 500,
        },
        medium: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/medium_Roberta_88deb7f916.jpeg",
          hash: "medium_Roberta_88deb7f916",
          mime: "image/jpeg",
          path: null,
          size: 77.08,
          width: 750,
          height: 750,
        },
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_Roberta_88deb7f916.jpeg",
          hash: "thumbnail_Roberta_88deb7f916",
          mime: "image/jpeg",
          path: null,
          size: 5.07,
          width: 156,
          height: 156,
        },
      },
      hash: "Roberta_88deb7f916",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 89.58,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/Roberta_88deb7f916.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-06T18:59:01.834Z",
      updated_at: "2020-07-06T18:59:01.834Z",
    },
    name: "Roberta Fernandes",
    company:
      "UX/UI Designer | Product | Developer | Master Degree in Computer Science",
    linkedin: "https://www.linkedin.com/in/roberta-fernandes-653a7425/",
    github: null,
    created_at: "2020-07-06T18:59:18.764Z",
    updated_at: "2020-07-06T18:59:18.764Z",
  },
  {
    id: 28,
    avatar: {
      id: 31,
      name: "guto",
      alternativeText: "",
      caption: "",
      width: 500,
      height: 500,
      formats: {
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_guto_655cc53d70.jpeg",
          hash: "thumbnail_guto_655cc53d70",
          mime: "image/jpeg",
          path: null,
          size: 5.93,
          width: 156,
          height: 156,
        },
      },
      hash: "guto_655cc53d70",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 36.38,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/guto_655cc53d70.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-06T19:02:58.772Z",
      updated_at: "2020-07-06T19:02:58.772Z",
    },
    name: "Guto Schneider",
    company: "CEO na StartUp LoopBox",
    linkedin: "https://www.linkedin.com/in/gutoschneider/",
    github: null,
    created_at: "2020-07-06T19:03:02.187Z",
    updated_at: "2020-07-06T19:03:02.187Z",
  },
  {
    id: 34,
    avatar: {
      id: 42,
      name: "phs",
      alternativeText: "",
      caption: "",
      width: 478,
      height: 478,
      formats: {
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_phs_bd47d15ead.jpeg",
          hash: "thumbnail_phs_bd47d15ead",
          mime: "image/jpeg",
          path: null,
          size: 5.55,
          width: 156,
          height: 156,
        },
      },
      hash: "phs_bd47d15ead",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 31.73,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/phs_bd47d15ead.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-07T17:54:42.982Z",
      updated_at: "2020-07-07T17:54:42.982Z",
    },
    name: "Pedro Henrique Santana",
    company:
      "CEO da 4MM Inovações em Negócios | Criador do DRONI | Especialista em Gestão de Processos e Projetos Estratégicos",
    linkedin: "https://www.linkedin.com/in/phsantana/",
    github: null,
    created_at: "2020-07-07T14:33:16.255Z",
    updated_at: "2020-07-07T14:33:16.255Z",
  },
  {
    id: 26,
    avatar: {
      id: 36,
      name: "marlete",
      alternativeText: "",
      caption: "",
      width: 1089,
      height: 1317,
      formats: {
        large: {
          ext: ".png",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/large_marlete_6915003a5c.png",
          hash: "large_marlete_6915003a5c",
          mime: "image/png",
          path: null,
          size: 1640.52,
          width: 827,
          height: 1000,
        },
        small: {
          ext: ".png",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/small_marlete_6915003a5c.png",
          hash: "small_marlete_6915003a5c",
          mime: "image/png",
          path: null,
          size: 408.04,
          width: 413,
          height: 500,
        },
        medium: {
          ext: ".png",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/medium_marlete_6915003a5c.png",
          hash: "medium_marlete_6915003a5c",
          mime: "image/png",
          path: null,
          size: 908.08,
          width: 620,
          height: 750,
        },
        thumbnail: {
          ext: ".png",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_marlete_6915003a5c.png",
          hash: "thumbnail_marlete_6915003a5c",
          mime: "image/png",
          path: null,
          size: 44.89,
          width: 129,
          height: 156,
        },
      },
      hash: "marlete_6915003a5c",
      ext: ".png",
      mime: "image/png",
      size: 2777.73,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/marlete_6915003a5c.png",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-07T11:41:28.575Z",
      updated_at: "2020-07-07T11:41:28.575Z",
    },
    name: "Marlete Maria da Silva",
    company:
      "Doutoranda em Sistemas Mecatrônicos, Mestre em Engenharia Biomédica- UNB | Banco de Dados | Lógica de Programação",
    linkedin: "https://www.linkedin.com/in/marlett-m-86299a20/",
    github: null,
    created_at: "2020-07-06T18:55:19.845Z",
    updated_at: "2020-07-07T11:41:53.128Z",
  },
  {
    id: 31,
    avatar: {
      id: 37,
      name: "carlosalexandria",
      alternativeText: "",
      caption: "",
      width: 600,
      height: 600,
      formats: {
        small: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/small_carlosalexandria_b0b23aa076.jpeg",
          hash: "small_carlosalexandria_b0b23aa076",
          mime: "image/jpeg",
          path: null,
          size: 20.94,
          width: 500,
          height: 500,
        },
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_carlosalexandria_b0b23aa076.jpeg",
          hash: "thumbnail_carlosalexandria_b0b23aa076",
          mime: "image/jpeg",
          path: null,
          size: 3.97,
          width: 156,
          height: 156,
        },
      },
      hash: "carlosalexandria_b0b23aa076",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 28.03,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/carlosalexandria_b0b23aa076.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-07T12:50:22.898Z",
      updated_at: "2020-07-07T12:50:22.898Z",
    },
    name: "Carlos Alexandria",
    company:
      "Empreendedorismo e Gestão de Negócios | Divisão de Criação de Novos Produtos - Serpro",
    linkedin: "https://www.linkedin.com/in/carlos-alexandria/",
    github: null,
    created_at: "2020-07-07T12:50:45.085Z",
    updated_at: "2020-07-07T12:50:45.085Z",
  },
  {
    id: 33,
    avatar: {
      id: 39,
      name: "cesarm",
      alternativeText: "",
      caption: "",
      width: 800,
      height: 800,
      formats: {
        small: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/small_cesarm_5b89bfc8ba.jpeg",
          hash: "small_cesarm_5b89bfc8ba",
          mime: "image/jpeg",
          path: null,
          size: 18.37,
          width: 500,
          height: 500,
        },
        medium: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/medium_cesarm_5b89bfc8ba.jpeg",
          hash: "medium_cesarm_5b89bfc8ba",
          mime: "image/jpeg",
          path: null,
          size: 42.56,
          width: 750,
          height: 750,
        },
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_cesarm_5b89bfc8ba.jpeg",
          hash: "thumbnail_cesarm_5b89bfc8ba",
          mime: "image/jpeg",
          path: null,
          size: 2.88,
          width: 156,
          height: 156,
        },
      },
      hash: "cesarm_5b89bfc8ba",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 50.05,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/cesarm_5b89bfc8ba.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-07T13:51:38.648Z",
      updated_at: "2020-07-07T13:51:38.648Z",
    },
    name: "Cezar Macegoza",
    company:
      "CEO OniQ Consultoria e Tecnologia | Tecnologia Estratégica | Produtos Digitais | Inovação",
    linkedin: "https://www.linkedin.com/in/cezarmacegoza/",
    github: null,
    created_at: "2020-07-07T13:51:56.446Z",
    updated_at: "2020-07-07T13:51:56.446Z",
  },
  {
    id: 35,
    avatar: {
      id: 41,
      name: "leonardopinheiro",
      alternativeText: "",
      caption: "",
      width: 800,
      height: 800,
      formats: {
        small: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/small_leonardopinheiro_409ce047c2.jpeg",
          hash: "small_leonardopinheiro_409ce047c2",
          mime: "image/jpeg",
          path: null,
          size: 23.88,
          width: 500,
          height: 500,
        },
        medium: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/medium_leonardopinheiro_409ce047c2.jpeg",
          hash: "medium_leonardopinheiro_409ce047c2",
          mime: "image/jpeg",
          path: null,
          size: 44.84,
          width: 750,
          height: 750,
        },
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_leonardopinheiro_409ce047c2.jpeg",
          hash: "thumbnail_leonardopinheiro_409ce047c2",
          mime: "image/jpeg",
          path: null,
          size: 4.4,
          width: 156,
          height: 156,
        },
      },
      hash: "leonardopinheiro_409ce047c2",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 50.48,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/leonardopinheiro_409ce047c2.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-07T17:53:15.808Z",
      updated_at: "2020-07-07T17:53:15.808Z",
    },
    name: "Leonardo Pinheiro",
    company:
      "Director Startups Development | Consultant in Innovation & Digital Transformation | Mentor Startups",
    linkedin: "https://www.linkedin.com/in/leonardo-v-pinheiro/",
    github: null,
    created_at: "2020-07-07T17:53:43.507Z",
    updated_at: "2020-07-07T17:53:43.507Z",
  },
  {
    id: 36,
    avatar: {
      id: 43,
      name: "graciele",
      alternativeText: "",
      caption: "",
      width: 800,
      height: 800,
      formats: {
        small: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/small_graciele_1b830cfdf6.jpeg",
          hash: "small_graciele_1b830cfdf6",
          mime: "image/jpeg",
          path: null,
          size: 40.59,
          width: 500,
          height: 500,
        },
        medium: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/medium_graciele_1b830cfdf6.jpeg",
          hash: "medium_graciele_1b830cfdf6",
          mime: "image/jpeg",
          path: null,
          size: 75.31,
          width: 750,
          height: 750,
        },
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_graciele_1b830cfdf6.jpeg",
          hash: "thumbnail_graciele_1b830cfdf6",
          mime: "image/jpeg",
          path: null,
          size: 6.29,
          width: 156,
          height: 156,
        },
      },
      hash: "graciele_1b830cfdf6",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 85.97,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/graciele_1b830cfdf6.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-07T17:57:29.251Z",
      updated_at: "2020-07-07T17:57:29.251Z",
    },
    name: "Gracielle Guedes",
    company: "IEL/GO - Consultora em Inovação e Gestão Empresarial",
    linkedin: "https://www.linkedin.com/in/gracielleguedes/",
    github: null,
    created_at: "2020-07-07T17:57:52.307Z",
    updated_at: "2020-07-07T17:57:52.307Z",
  },
  {
    id: 37,
    avatar: {
      id: 44,
      name: "leonardofurquim",
      alternativeText: "",
      caption: "",
      width: 800,
      height: 800,
      formats: {
        small: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/small_leonardofurquim_779be85034.jpeg",
          hash: "small_leonardofurquim_779be85034",
          mime: "image/jpeg",
          path: null,
          size: 29.78,
          width: 500,
          height: 500,
        },
        medium: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/medium_leonardofurquim_779be85034.jpeg",
          hash: "medium_leonardofurquim_779be85034",
          mime: "image/jpeg",
          path: null,
          size: 64.36,
          width: 750,
          height: 750,
        },
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_leonardofurquim_779be85034.jpeg",
          hash: "thumbnail_leonardofurquim_779be85034",
          mime: "image/jpeg",
          path: null,
          size: 4.16,
          width: 156,
          height: 156,
        },
      },
      hash: "leonardofurquim_779be85034",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 74.81,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/leonardofurquim_779be85034.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-07T18:13:19.105Z",
      updated_at: "2020-07-07T18:13:19.105Z",
    },
    name: "Leonnardo Cruvinel Furquim",
    company:
      "Senar Goiás - Gerente de Formação Profissional Rural | Engenheiro Agrônomo, Químico, Mestre em Agroquímica, Doutor em Agronomia",
    linkedin:
      "https://www.linkedin.com/in/leonnardo-cruvinel-furquim-4280b7196/",
    github: null,
    created_at: "2020-07-07T18:13:24.389Z",
    updated_at: "2020-07-07T18:13:24.389Z",
  },
  {
    id: 38,
    avatar: {
      id: 45,
      name: "alexandrosantos",
      alternativeText: "",
      caption: "",
      width: 500,
      height: 500,
      formats: {
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_alexandrosantos_f64868da71.jpeg",
          hash: "thumbnail_alexandrosantos_f64868da71",
          mime: "image/jpeg",
          path: null,
          size: 5.17,
          width: 156,
          height: 156,
        },
      },
      hash: "alexandrosantos_f64868da71",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 30.58,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/alexandrosantos_f64868da71.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-07T18:44:06.269Z",
      updated_at: "2020-07-07T18:44:06.269Z",
    },
    name: "Alexandro Santos",
    company:
      "Coordenador Técnico do Instituto para Fortalecimento da Agropecuária de Goiás IFAG",
    linkedin: "https://www.linkedin.com/in/alexandro-santos/",
    github: null,
    created_at: "2020-07-07T18:44:29.856Z",
    updated_at: "2020-07-07T18:44:29.856Z",
  },
  {
    id: 39,
    avatar: {
      id: 46,
      name: "FERNANDOBORGES",
      alternativeText: "",
      caption: "",
      width: 450,
      height: 450,
      formats: {
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_FERNANDOBORGES_4af89ab316.jpeg",
          hash: "thumbnail_FERNANDOBORGES_4af89ab316",
          mime: "image/jpeg",
          path: null,
          size: 5.14,
          width: 156,
          height: 156,
        },
      },
      hash: "FERNANDOBORGES_4af89ab316",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 30.77,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/FERNANDOBORGES_4af89ab316.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-07T18:49:24.498Z",
      updated_at: "2020-07-07T18:49:24.498Z",
    },
    name: "Fernando Borges Fernandes",
    company: "Gerente de Inovação | SENAR/AR-GO",
    linkedin: "https://www.linkedin.com/in/fernandoborgesfernandes/",
    github: null,
    created_at: "2020-07-07T18:50:00.286Z",
    updated_at: "2020-07-07T18:50:00.286Z",
  },
  {
    id: 40,
    avatar: {
      id: 47,
      name: "adrianacarla",
      alternativeText: "",
      caption: "",
      width: 200,
      height: 200,
      formats: {
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_adrianacarla_132453ac0f.jpeg",
          hash: "thumbnail_adrianacarla_132453ac0f",
          mime: "image/jpeg",
          path: null,
          size: 6.9,
          width: 156,
          height: 156,
        },
      },
      hash: "adrianacarla_132453ac0f",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 10.28,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/adrianacarla_132453ac0f.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-07T19:11:04.125Z",
      updated_at: "2020-07-07T19:11:04.125Z",
    },
    name: "Adriana Carla de Araújo Simões",
    company:
      "Serpro - Analista de Sistemas do SERPRO | Coordenação Estratégica de TI",
    linkedin: "https://www.linkedin.com/in/adrianasimoes/",
    github: null,
    created_at: "2020-07-07T19:11:22.227Z",
    updated_at: "2020-07-07T19:11:22.227Z",
  },
  {
    id: 41,
    avatar: {
      id: 48,
      name: "ANAMAIARA",
      alternativeText: "",
      caption: "",
      width: 500,
      height: 500,
      formats: {
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_ANAMAIARA_ca6062bd84.jpeg",
          hash: "thumbnail_ANAMAIARA_ca6062bd84",
          mime: "image/jpeg",
          path: null,
          size: 5.73,
          width: 156,
          height: 156,
        },
      },
      hash: "ANAMAIARA_ca6062bd84",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 48.3,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/ANAMAIARA_ca6062bd84.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-07T19:16:39.547Z",
      updated_at: "2020-07-07T19:16:39.547Z",
    },
    name: "Ana Maiara Rodrigues",
    company:
      "Economista | Professora - UniRV | Apresentadora do Podcast TechBusiness",
    linkedin: "https://www.linkedin.com/in/anamaiara/",
    github: null,
    created_at: "2020-07-07T19:17:14.616Z",
    updated_at: "2020-07-07T19:17:14.616Z",
  },
  {
    id: 42,
    avatar: {
      id: 49,
      name: "Christian",
      alternativeText: "",
      caption: "",
      width: 592,
      height: 592,
      formats: {
        small: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/small_Christian_9d73bdcfb3.jpeg",
          hash: "small_Christian_9d73bdcfb3",
          mime: "image/jpeg",
          path: null,
          size: 31.52,
          width: 500,
          height: 500,
        },
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_Christian_9d73bdcfb3.jpeg",
          hash: "thumbnail_Christian_9d73bdcfb3",
          mime: "image/jpeg",
          path: null,
          size: 5.64,
          width: 156,
          height: 156,
        },
      },
      hash: "Christian_9d73bdcfb3",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 41.64,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/Christian_9d73bdcfb3.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-07T19:23:59.195Z",
      updated_at: "2020-07-07T19:23:59.195Z",
    },
    name: "Christian Martin Cancharez Aguirre",
    company:
      "Especialista de Innovación MAPFRE PERU │ Embajador THE POWER MBA | Fundador EGRESADOS UNFV",
    linkedin: "https://www.linkedin.com/in/christiancancharezaguirre/",
    github: null,
    created_at: "2020-07-07T19:25:35.811Z",
    updated_at: "2020-07-07T19:25:35.811Z",
  },
  {
    id: 43,
    avatar: {
      id: 50,
      name: "felipeconsaulter",
      alternativeText: "",
      caption: "",
      width: 800,
      height: 800,
      formats: {
        small: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/small_felipeconsaulter_a74e8d5eba.jpeg",
          hash: "small_felipeconsaulter_a74e8d5eba",
          mime: "image/jpeg",
          path: null,
          size: 43.11,
          width: 500,
          height: 500,
        },
        medium: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/medium_felipeconsaulter_a74e8d5eba.jpeg",
          hash: "medium_felipeconsaulter_a74e8d5eba",
          mime: "image/jpeg",
          path: null,
          size: 100.74,
          width: 750,
          height: 750,
        },
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_felipeconsaulter_a74e8d5eba.jpeg",
          hash: "thumbnail_felipeconsaulter_a74e8d5eba",
          mime: "image/jpeg",
          path: null,
          size: 5.83,
          width: 156,
          height: 156,
        },
      },
      hash: "felipeconsaulter_a74e8d5eba",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 120.21,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/felipeconsaulter_a74e8d5eba.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-07T19:30:08.962Z",
      updated_at: "2020-07-07T19:30:08.962Z",
    },
    name: "Felipe Consalter",
    company: "Co-founder and CEO Athena Agro",
    linkedin: "https://www.linkedin.com/in/felipe-consalter-9747b547/",
    github: "https://github.com/felipecongb",
    created_at: "2020-07-07T19:30:12.664Z",
    updated_at: "2020-07-07T19:30:12.664Z",
  },
  {
    id: 44,
    avatar: {
      id: 51,
      name: "franciele",
      alternativeText: "",
      caption: "",
      width: 446,
      height: 446,
      formats: {
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_franciele_a208240102.jpeg",
          hash: "thumbnail_franciele_a208240102",
          mime: "image/jpeg",
          path: null,
          size: 6.68,
          width: 156,
          height: 156,
        },
      },
      hash: "franciele_a208240102",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 35.19,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/franciele_a208240102.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-07T19:36:03.711Z",
      updated_at: "2020-07-07T19:36:03.711Z",
    },
    name: "Francielle Felipe",
    company:
      "Publicitária, especialista em Marketing e Comunicação e Mestre em Comunicação pela Universidade Federal de Goiás.",
    linkedin: "https://www.linkedin.com/in/francielle-felipe-a2412b68/",
    github: null,
    created_at: "2020-07-07T19:36:07.187Z",
    updated_at: "2020-07-07T19:36:07.187Z",
  },
  {
    id: 45,
    avatar: {
      id: 52,
      name: "ivanlucas",
      alternativeText: "",
      caption: "",
      width: 270,
      height: 308,
      formats: {
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_ivanlucas_dbd37a1432.jpeg",
          hash: "thumbnail_ivanlucas_dbd37a1432",
          mime: "image/jpeg",
          path: null,
          size: 5.66,
          width: 137,
          height: 156,
        },
      },
      hash: "ivanlucas_dbd37a1432",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 17.31,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/ivanlucas_dbd37a1432.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-07T19:53:26.096Z",
      updated_at: "2020-07-07T19:53:26.096Z",
    },
    name: "Ivan Lucas de Paula",
    company:
      "Coordenação de Marketing do Sistema Faeg Senar | Publicitário | Profissional de Marketing | Consultor Estratégico",
    linkedin: "https://www.linkedin.com/in/ivanlucas/",
    github: null,
    created_at: "2020-07-07T19:53:28.817Z",
    updated_at: "2020-07-07T19:53:28.817Z",
  },
  {
    id: 46,
    avatar: {
      id: 53,
      name: "luizcarvalho",
      alternativeText: "",
      caption: "",
      width: 500,
      height: 500,
      formats: {
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_luizcarvalho_30365497c7.jpeg",
          hash: "thumbnail_luizcarvalho_30365497c7",
          mime: "image/jpeg",
          path: null,
          size: 8.04,
          width: 156,
          height: 156,
        },
      },
      hash: "luizcarvalho_30365497c7",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 47.26,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/luizcarvalho_30365497c7.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-07T20:03:09.835Z",
      updated_at: "2020-07-07T20:03:09.835Z",
    },
    name: "Luiz Carvalho",
    company: "Analista de TI na Defensoria Pública do Estado do Tocantins",
    linkedin: "https://www.linkedin.com/in/luizcarvalhodev/",
    github: "https://github.com/luizcarvalho",
    created_at: "2020-07-07T20:03:40.890Z",
    updated_at: "2020-07-07T20:03:40.890Z",
  },
  {
    id: 48,
    avatar: {
      id: 55,
      name: "marcusveloso",
      alternativeText: "",
      caption: "",
      width: 592,
      height: 592,
      formats: {
        small: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/small_marcusveloso_01bd9e39ec.jpeg",
          hash: "small_marcusveloso_01bd9e39ec",
          mime: "image/jpeg",
          path: null,
          size: 33.63,
          width: 500,
          height: 500,
        },
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_marcusveloso_01bd9e39ec.jpeg",
          hash: "thumbnail_marcusveloso_01bd9e39ec",
          mime: "image/jpeg",
          path: null,
          size: 4.3,
          width: 156,
          height: 156,
        },
      },
      hash: "marcusveloso_01bd9e39ec",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 45.22,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/marcusveloso_01bd9e39ec.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-07T20:14:16.651Z",
      updated_at: "2020-07-07T20:14:16.651Z",
    },
    name: "Marcus Veloso",
    company: "Software Developer at Nortesys",
    linkedin: "https://www.linkedin.com/in/marcus-veloso-148b5148/",
    github: "https://github.com/MarcusVeloso",
    created_at: "2020-07-07T20:14:21.033Z",
    updated_at: "2020-07-07T20:14:21.033Z",
  },
  {
    id: 49,
    avatar: {
      id: 56,
      name: "sandrarigatto",
      alternativeText: "",
      caption: "",
      width: 560,
      height: 560,
      formats: {
        small: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/small_sandrarigatto_b0f65935ce.jpeg",
          hash: "small_sandrarigatto_b0f65935ce",
          mime: "image/jpeg",
          path: null,
          size: 41.33,
          width: 500,
          height: 500,
        },
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_sandrarigatto_b0f65935ce.jpeg",
          hash: "thumbnail_sandrarigatto_b0f65935ce",
          mime: "image/jpeg",
          path: null,
          size: 6.72,
          width: 156,
          height: 156,
        },
      },
      hash: "sandrarigatto_b0f65935ce",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 48.69,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/sandrarigatto_b0f65935ce.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-07T20:20:26.607Z",
      updated_at: "2020-07-07T20:20:26.607Z",
    },
    name: "Sandra Rigatto",
    company:
      "Professora Universitária | Gestão de Empresas e Novos Negócios | Turismo | Projeto Social",
    linkedin: "https://www.linkedin.com/in/sandra-rigatto-7691b434/",
    github: null,
    created_at: "2020-07-07T20:20:30.702Z",
    updated_at: "2020-07-07T20:20:30.702Z",
  },
  {
    id: 47,
    avatar: {
      id: 57,
      name: "0",
      alternativeText: "",
      caption: "",
      width: 400,
      height: 400,
      formats: {
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_0_1d5c215137.jpeg",
          hash: "thumbnail_0_1d5c215137",
          mime: "image/jpeg",
          path: null,
          size: 5.89,
          width: 156,
          height: 156,
        },
      },
      hash: "0_1d5c215137",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 26.21,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/0_1d5c215137.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-08T12:41:25.286Z",
      updated_at: "2020-07-08T12:41:25.286Z",
    },
    name: "Marco Antonio",
    company:
      "Experiência na área de Ciência da Computação, com ênfase em Robótica, atuando principalmente nos seguintes temas: Futebol de Robôs, Mineração de Dados e Integração de Sistemas",
    linkedin: "https://www.linkedin.com/in/marco-antonio-f-7a57ab18/",
    github: "https://github.com/sousamaf",
    created_at: "2020-07-07T20:09:21.703Z",
    updated_at: "2020-07-07T20:09:21.703Z",
  },
  {
    id: 16,
    avatar: {
      id: 58,
      name: "Ass Jeonat",
      alternativeText: "",
      caption: "",
      width: 969,
      height: 968,
      formats: {
        small: {
          ext: ".png",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/small_Ass_Jeonat_9ea02e405b.png",
          hash: "small_Ass_Jeonat_9ea02e405b",
          mime: "image/png",
          path: null,
          size: 444.18,
          width: 500,
          height: 499,
        },
        medium: {
          ext: ".png",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/medium_Ass_Jeonat_9ea02e405b.png",
          hash: "medium_Ass_Jeonat_9ea02e405b",
          mime: "image/png",
          path: null,
          size: 906.34,
          width: 750,
          height: 749,
        },
        thumbnail: {
          ext: ".png",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_Ass_Jeonat_9ea02e405b.png",
          hash: "thumbnail_Ass_Jeonat_9ea02e405b",
          mime: "image/png",
          path: null,
          size: 54.68,
          width: 156,
          height: 156,
        },
      },
      hash: "Ass_Jeonat_9ea02e405b",
      ext: ".png",
      mime: "image/png",
      size: 1260.82,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/Ass_Jeonat_9ea02e405b.png",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-08T16:04:28.025Z",
      updated_at: "2020-07-08T16:04:28.025Z",
    },
    name: "Jeonatha Gualberto",
    company:
      "CEO na Startup GetJus | Empreendedor | Team Leader | Data Driven | Mentor | Head of Product at GetJus",
    linkedin: "https://www.linkedin.com/in/jeonatha-g-55b314169",
    github: null,
    created_at: "2020-07-06T17:14:50.420Z",
    updated_at: "2020-07-08T16:05:25.285Z",
  },
  {
    id: 52,
    avatar: {
      id: 61,
      name: "wanderson",
      alternativeText: "",
      caption: "",
      width: 500,
      height: 500,
      formats: {
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_wanderson_ec0321f551.jpeg",
          hash: "thumbnail_wanderson_ec0321f551",
          mime: "image/jpeg",
          path: null,
          size: 5.66,
          width: 156,
          height: 156,
        },
      },
      hash: "wanderson_ec0321f551",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 37.9,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/wanderson_ec0321f551.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-08T18:40:43.671Z",
      updated_at: "2020-07-08T18:40:43.671Z",
    },
    name: "Wanderson da Silva Marques",
    company:
      "Coordenador de Ciência de Dados / Gerência de Inovação / Secretaria de Estado da Saúde de Goiás",
    linkedin: "https://www.linkedin.com/in/wandersondsm/",
    github: null,
    created_at: "2020-07-08T18:40:51.989Z",
    updated_at: "2020-07-08T18:40:51.989Z",
  },
  {
    id: 53,
    avatar: {
      id: 62,
      name: "romullo",
      alternativeText: "",
      caption: "",
      width: 600,
      height: 600,
      formats: {
        small: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/small_romullo_8d8ed85302.jpeg",
          hash: "small_romullo_8d8ed85302",
          mime: "image/jpeg",
          path: null,
          size: 35.71,
          width: 500,
          height: 500,
        },
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_romullo_8d8ed85302.jpeg",
          hash: "thumbnail_romullo_8d8ed85302",
          mime: "image/jpeg",
          path: null,
          size: 5.7,
          width: 156,
          height: 156,
        },
      },
      hash: "romullo_8d8ed85302",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 49.46,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/romullo_8d8ed85302.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-08T19:21:24.689Z",
      updated_at: "2020-07-08T19:21:24.689Z",
    },
    name: "Romullo Mota",
    company: "Analista de Dados no Instituto Euvaldo Lodi - IEL/GO",
    linkedin: "https://www.linkedin.com/in/romullo-mota-13a9a1169/",
    github: null,
    created_at: "2020-07-08T19:21:28.356Z",
    updated_at: "2020-07-08T19:21:28.356Z",
  },
  {
    id: 50,
    avatar: {
      id: 59,
      name: "avila",
      alternativeText: "",
      caption: "",
      width: 640,
      height: 640,
      formats: {
        small: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/small_avila_9c3b6c0b11.jpeg",
          hash: "small_avila_9c3b6c0b11",
          mime: "image/jpeg",
          path: null,
          size: 50.53,
          width: 500,
          height: 500,
        },
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_avila_9c3b6c0b11.jpeg",
          hash: "thumbnail_avila_9c3b6c0b11",
          mime: "image/jpeg",
          path: null,
          size: 7.36,
          width: 156,
          height: 156,
        },
      },
      hash: "avila_9c3b6c0b11",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 69.54,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/avila_9c3b6c0b11.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-08T16:21:20.058Z",
      updated_at: "2020-07-08T16:21:20.058Z",
    },
    name: "Claudio Avila",
    company:
      "Diretor da ACIEG | CEO - Supera inovacao em Tecnologia | Local Leader - Founder Institute | Mentor Inovativa",
    linkedin: "https://www.linkedin.com/in/claudio-avila-445623",
    github: null,
    created_at: "2020-07-08T16:22:11.475Z",
    updated_at: "2020-07-09T01:21:11.240Z",
  },
  {
    id: 54,
    avatar: {
      id: 63,
      name: "WhatsApp Image 2020-07-09 at 13.52.07",
      alternativeText: "",
      caption: "",
      width: 428,
      height: 507,
      formats: {
        small: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/small_Whats_App_Image_2020_07_09_at_13_52_07_1227b3c5dc.jpeg",
          hash: "small_Whats_App_Image_2020_07_09_at_13_52_07_1227b3c5dc",
          mime: "image/jpeg",
          path: null,
          size: 31.16,
          width: 422,
          height: 500,
        },
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_Whats_App_Image_2020_07_09_at_13_52_07_1227b3c5dc.jpeg",
          hash: "thumbnail_Whats_App_Image_2020_07_09_at_13_52_07_1227b3c5dc",
          mime: "image/jpeg",
          path: null,
          size: 4.57,
          width: 132,
          height: 156,
        },
      },
      hash: "Whats_App_Image_2020_07_09_at_13_52_07_1227b3c5dc",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 32.37,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/Whats_App_Image_2020_07_09_at_13_52_07_1227b3c5dc.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-09T16:55:23.345Z",
      updated_at: "2020-07-09T16:55:23.345Z",
    },
    name: "Manoel Alexandre de Moura",
    company: "Análise de Dados",
    linkedin: null,
    github: null,
    created_at: "2020-07-09T16:55:31.254Z",
    updated_at: "2020-07-09T16:55:31.254Z",
  },
  {
    id: 51,
    avatar: {
      id: 60,
      name: "carloshowe",
      alternativeText: "",
      caption: "",
      width: 657,
      height: 869,
      formats: {
        small: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/small_carloshowe_235f2d3ebd.jpeg",
          hash: "small_carloshowe_235f2d3ebd",
          mime: "image/jpeg",
          path: null,
          size: 20.75,
          width: 378,
          height: 500,
        },
        medium: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/medium_carloshowe_235f2d3ebd.jpeg",
          hash: "medium_carloshowe_235f2d3ebd",
          mime: "image/jpeg",
          path: null,
          size: 37.45,
          width: 567,
          height: 750,
        },
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_carloshowe_235f2d3ebd.jpeg",
          hash: "thumbnail_carloshowe_235f2d3ebd",
          mime: "image/jpeg",
          path: null,
          size: 3.79,
          width: 118,
          height: 156,
        },
      },
      hash: "carloshowe_235f2d3ebd",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 45.07,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/carloshowe_235f2d3ebd.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-08T18:39:44.221Z",
      updated_at: "2020-07-08T18:39:44.221Z",
    },
    name: "Carlos Howes",
    company: "Gerente de TI na AGRODEFESA",
    linkedin: "https://www.linkedin.com/in/carlos-howes-1406b5199/",
    github: null,
    created_at: "2020-07-08T18:39:49.664Z",
    updated_at: "2020-07-09T17:27:01.625Z",
  },
  {
    id: 55,
    avatar: {
      id: 64,
      name: "leandro",
      alternativeText: "",
      caption: "",
      width: 895,
      height: 671,
      formats: {
        small: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/small_leandro_2ef9003334.jpeg",
          hash: "small_leandro_2ef9003334",
          mime: "image/jpeg",
          path: null,
          size: 37.72,
          width: 500,
          height: 375,
        },
        medium: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/medium_leandro_2ef9003334.jpeg",
          hash: "medium_leandro_2ef9003334",
          mime: "image/jpeg",
          path: null,
          size: 69.92,
          width: 750,
          height: 562,
        },
        thumbnail: {
          ext: ".jpeg",
          url:
            "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/thumbnail_leandro_2ef9003334.jpeg",
          hash: "thumbnail_leandro_2ef9003334",
          mime: "image/jpeg",
          path: null,
          size: 8.82,
          width: 208,
          height: 156,
        },
      },
      hash: "leandro_2ef9003334",
      ext: ".jpeg",
      mime: "image/jpeg",
      size: 86.9,
      url:
        "https://hackathon-digital-images.s3.sa-east-1.amazonaws.com/leandro_2ef9003334.jpeg",
      previewUrl: null,
      provider: "aws-s3",
      provider_metadata: null,
      created_at: "2020-07-10T11:21:42.468Z",
      updated_at: "2020-07-10T11:21:42.468Z",
    },
    name: "Leandro Souza",
    company:
      "Professor IFGoiano | Pesquisador | Developer FullStack | IoT | Cientista de Dados",
    linkedin:
      "https://www.linkedin.com/in/leandro-rodrigues-da-silva-souza-a81ba827/",
    github: null,
    created_at: "2020-07-10T11:22:25.195Z",
    updated_at: "2020-07-10T11:22:25.195Z",
  },
]

export default function Mentors({ pages, links }) {
  return (
    <Container id="mentores">
      <div>
        <h2>Mentores</h2>
        <Markdown>
          {`É preciso mais do que saber escrever linhas de código para alcançar os
          objetivos propostos na hackaton. O tempo é curto, por isso, o bom
          mesmo é ter uma equipe parceira e trocar ideias com os mentores do
          evento.\n\nEles são especialistas, atuantes no mercado e na academia,
          que têm conhecimento e dicas valiosas para ajudar os participantes. Os
          mentores não oferecem soluções, quem coloca a mão na massa são os
          participantes que formaram as equipes selecionadas. \n\nOs mentores
          vão entrar no grupo de cada equipe no Discord, mas também podem ser
          chamados lá no chat de interação. Para aproveitar melhor o tempo com o
          mentor prepare-se:\n\n- Pense e deixe perguntas preparadas para o
          mentor da área para o atedimento ser ágil e sua equipe não perder
          tempo.\n- Não foque apenas nos problemas, compartilhe ideias. Assim o
          mentor poderá dar mais dicas sobre como avançar.\n- Tome notas. Saiba
          com quem conversou e o que foi sugerido.\n- Tudo pronto? Chama um
          mentor e faz sua apresentação para ele!`}
        </Markdown>

        <People>
          {mentors.map((mentor, index) => (
            <div key={`mentor-${String(index)}`}>
              <img
                src={
                  mentor?.avatar?.formats?.thumbnail?.url ||
                  `https://api.adorable.io/avatars/285/${mentor.id}@adorable.png`
                }
              />
              <strong>{mentor.name}</strong>
              <span>{mentor.company}</span>
              <ul>
                {mentor.github && (
                  <li>
                    <Link to={mentor.github} target="_blank">
                      <FaGithub />
                    </Link>
                  </li>
                )}
                {mentor.linkedin && (
                  <li>
                    <Link to={mentor.linkedin} target="_blank">
                      <FaLinkedin />
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          ))}
        </People>

        {/* <Link
          to={mentorForm?.link || '/'}
          className="button"
          target="_blank"
        >
          <span>Seja um Mentor</span>
          <span>Inscreva-se para apoiar o evento!</span>
        </Link> */}
      </div>
    </Container>
  )
}
