import React, { useState, useEffect, useMemo } from "react"
import { Link } from "gatsby"

import Markdown from "react-markdown"

import api from "~/services/api"

import { Container, Steps, Buttons } from "./styles"

const steps = [
  {
    name: "Inscreva-se",
    description: "até 10/07 às 15:00",
    active: true,
    link: "",
  },
  {
    name: "Leia o Regulamento",
    description: "clique aqui para baixar",
    active: true,
    link: "",
  },
  {
    name: "Entre no Discord",
    description: "clique aqui para entrar",
    active: true,
    link: "",
  },
  {
    name: "Assista ao Evento",
    description: "ao vivo 09/07 11:00",
    active: true,
    link: "",
  },
  {
    name: "Envie o seu projeto",
    description: "disponível 10/07 11:00",
    active: false,
    link: "",
  },
]

export default function Hackathon() {
  return (
    <Container id="hackathon">
      <div>
        <h2>Hackathon Goiás Digital 2020</h2>
        <Markdown>
          {`**HACKATHON GOIÁS DIGITAL – Desafios com dados abertos** é uma
          maratona de programação na qual estudantes e profissionais das áreas
          de design, programação, análise de dados e quaisquer outros com ideias
          inovadoras vão se reunir durante 24 horas para construir soluções
          impactantes relacionadas a agronegócios, logística, indústria, saúde e
          educação, e educação, ação social. O que liga todas essas áreas é que
          as soluções terão como fonte de informação bases de dados
          abertos!\n\nA maratona faz parte da programação da Campus Party Goiás
          Digital, é primeira edição completamente virtual do evento. \n\n**Para
          sua experiência ser completa se ligue nesses detalhes:**\n\n- 9, 10,
          11 de julho de 2020, evento completamente digital na plataforma
          Discord, com transmissão para o palco da hackathon.\n- 24 horas de
          maratona de desenvolvimento de solução (sexta-feira, das 15h até
          sábado às 15 horas).\n- No total, são mais de 50 horas com atividades
          educativas, de criatividade, e mentorias. \n- Formação e integração
          das equipes (com no mínimo 4 e máximo 5 integrantes).\n- Desenvolvimento de soluções com dados abertos nas áreas de
          agronegócio, logística, indústria, saúde e educação, e educação, ação
          social.\n- Mentorias com profissionais de mercado e da área
          acadêmica.\n- Conteúdo de apoio (bases de dados e ferramentas).\n\n`}
        </Markdown>

        <h3>Quem pode participar?</h3>
        <Markdown>
          {`Há duas formas de fazer parte: como [participante dos desafios](
          #equipes ) ou como [mentor](#mentores).\n\nParticipantes formarão as
          equipes para cumprir os desafios, e os mentores vão apoiá-las no
          desenvolvimento das soluções, avaliando ideias, fazendo sugestões
          técnicas e de negócios.\n\nOs perfis para inscrição:\n\n- Design\n- Desenvolvimento\n- Análise de dados\n- Gestão de idéias\n\n`}
        </Markdown>

        {/* <Buttons>
          <Link to="https://docs.google.com/forms/d/1vRXQGt6rrLNQAaff6KmnBcPk7s7T0XJdSJFqwaze1OU/edit?usp=sharing" target="_blank">
            <strong>Seja um Mentor</strong>
            <span>As inscrições foram encerradas!</span>
          </Link>

          <Link to="https://docs.google.com/forms/d/1sIspxspCuK0VABk5Ofj3vxFEZeIesh4OSTOw-C2vR-4/edit?usp=sharing" target="_blank">
            <strong>Inscreva-se</strong>
            <span>Chame seus colegas e participe!</span>
          </Link>
        </Buttons> */}

        <div id="premiacao"></div>
        <h3>Premiação</h3>
        <Markdown>
          {`- **1º lugar:** Bootcamp Sebrae + 2 bolsas pós-graduação IEL + 2
          bolsas Fapeg/CEIA (valor compatível com a graduação)\n\n- ** 2º
          lugar:** Bootcamp Sebrae + 1 bolsa pós-graduação IEL + 2 bolsas
          Fapeg/CEIA (valor compatível com a graduação)\n\n- **3º lugar:**
          Bootcamp Sebrae\n\n\n*Bolsas do IEL: poderão ser requisitadas pelos 2º
          ou 3º lugares caso não seja possível pelo antecessor.\n\n*Bolsas
          Fapeg/CEIA: serão 2 bolsas por projeto e poderão ser requisitadas por
          integrantes das equipes classificadas para 'Fase 2' caso não seja
          possível pelo antecessor e desde que comprovem competência técnica
          para execução de projeto que atenda interesses do Estado. Os projetos
          a serem implementados não precisam ser, necessariamente, os
          apresentados no final da maratona.\n\n*O Bootcamp Sebrae será o prêmio
          em que todos vencedores - 1º, 2º e 3º lugares - serão
          contemplados.\n\nOs demais prêmios possuem fatores condicionais de
          graduação e disponibilidade, sendo que o time vencedor deverá indicar
          as pessoas que farão uso.`}
        </Markdown>
      </div>
    </Container>
  )
}
