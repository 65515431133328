import React, { useEffect, useState } from "react"
import moment from "moment"

import api from "~/services/api"

import Loading from "~/components/Loading"

import { Container } from "./styles"

const schedules = [
  {
    id: 2,
    date: "2020-07-09T17:00:00.000Z",
    name: "Abertura",
    speaker: "Pedro Renan",
    created_at: "2020-06-26T18:18:30.790Z",
    updated_at: "2020-07-09T17:38:56.534Z",
  },
  {
    id: 4,
    date: "2020-07-09T17:05:00.000Z",
    name: "Apresentação",
    speaker:
      "Marcio César Pereira - Secretário de Estado de Desenvolvimento e Inovação",
    created_at: "2020-06-26T21:03:01.752Z",
    updated_at: "2020-07-09T15:10:24.342Z",
  },
  {
    id: 5,
    date: "2020-07-09T17:20:00.000Z",
    name: "A Hackathon",
    speaker: "Pedro Renan",
    created_at: "2020-06-26T21:03:29.524Z",
    updated_at: "2020-07-09T15:11:02.640Z",
  },
  {
    id: 6,
    date: "2020-07-09T17:30:00.000Z",
    name: "Desafios",
    speaker:
      "Wanderson Marques - Secretaria de Saúde do Estado de Goiás; Rômullo Mota - Sistema FIEG; Francisco Calaça - CampoLab/Faeg",
    created_at: "2020-06-26T21:03:47.366Z",
    updated_at: "2020-07-09T17:38:10.179Z",
  },
  {
    id: 7,
    date: "2020-07-09T18:00:00.000Z",
    name: "Apresentação do Portal de Dados Abertos",
    speaker: "Celso Camilo - Subsecretário de Tecnologia da Informação da SEDI",
    created_at: "2020-06-26T21:04:18.242Z",
    updated_at: "2020-07-09T17:33:53.600Z",
  },
  {
    id: 8,
    date: "2020-07-09T18:15:00.000Z",
    name: "APIs do Serpro",
    speaker: "Eliezer Trajano da Silva - Serpro",
    created_at: "2020-06-26T21:04:34.549Z",
    updated_at: "2020-07-09T15:17:20.503Z",
  },
  {
    id: 9,
    date: "2020-07-09T18:30:00.000Z",
    name: "Explicações sobre como formar equipes",
    speaker: "Pedro Renan",
    created_at: "2020-06-26T21:05:04.185Z",
    updated_at: "2020-07-09T15:14:51.882Z",
  },
  {
    id: 14,
    date: "2020-07-10T09:59:00.000Z",
    name: "Prazo final para entrega de propostas",
    speaker: null,
    created_at: "2020-07-10T21:58:10.043Z",
    updated_at: "2020-07-10T21:58:10.043Z",
  },
  {
    id: 11,
    date: "2020-07-10T17:00:00.000Z",
    name: "Anúncio das Equipes selecionadas - no Discord e no palco",
    speaker: "Pedro Renan",
    created_at: "2020-07-10T13:31:30.512Z",
    updated_at: "2020-07-10T13:31:30.512Z",
  },
  {
    id: 15,
    date: "2020-07-10T18:00:00.000Z",
    name: "Início da maratona",
    speaker: null,
    created_at: "2020-07-10T21:58:43.704Z",
    updated_at: "2020-07-10T21:58:43.704Z",
  },
  {
    id: 16,
    date: "2020-07-10T19:00:00.000Z",
    name: "Início das mentorias",
    speaker: null,
    created_at: "2020-07-10T21:59:02.719Z",
    updated_at: "2020-07-10T21:59:02.719Z",
  },
  {
    id: 12,
    date: "2020-07-11T12:00:00.000Z",
    name: "Meditação",
    speaker: "Valentina Tahan",
    created_at: "2020-07-10T21:55:58.535Z",
    updated_at: "2020-07-10T21:55:58.535Z",
  },
  {
    id: 13,
    date: "2020-07-11T14:00:00.000Z",
    name: "Como fazer meu Pitch",
    speaker: "Fernando Tomé",
    created_at: "2020-07-10T21:56:53.476Z",
    updated_at: "2020-07-10T21:56:53.476Z",
  },
  {
    id: 17,
    date: "2020-07-11T17:00:00.000Z",
    name: "Abertura do formulário de envio de soluções - Link no Discord",
    speaker: null,
    created_at: "2020-07-10T22:04:25.636Z",
    updated_at: "2020-07-10T22:08:41.056Z",
  },
  {
    id: 18,
    date: "2020-07-11T18:00:00.000Z",
    name: "Prazo final para entrega de soluções - Link no Discord",
    speaker: null,
    created_at: "2020-07-10T22:07:33.386Z",
    updated_at: "2020-07-10T22:07:33.386Z",
  },
  {
    id: 19,
    date: "2020-07-11T18:00:00.000Z",
    name: "Apresentação dos avaliadores",
    speaker: "Pedro Renan",
    created_at: "2020-07-10T22:09:59.963Z",
    updated_at: "2020-07-10T22:09:59.963Z",
  },
  {
    id: 20,
    date: "2020-07-11T18:30:00.000Z",
    name: "Início da apresentação dos Pitchs",
    speaker: "Equipes",
    created_at: "2020-07-10T22:10:44.422Z",
    updated_at: "2020-07-10T22:10:44.422Z",
  },
]

export default function Scheduler() {
  return (
    <Container>
      <div id="cronograma">
        <h2>Cronograma</h2>

        <section>
          <table>
            <thead>
              <tr>
                <th width="25%">Horário</th>
                <th width="55%">Atividade</th>
                <th width="20%">Responsável</th>
              </tr>
            </thead>

            <tbody>
              {schedules.map((schedule, index) => (
                <tr key={String(`schedule-${index}`)}>
                  <td width="25%">{schedule.date}</td>
                  <td width="55%">{schedule.name}</td>
                  <td width="20%">{schedule.speaker}</td>
                </tr>
              ))}
              )}
            </tbody>
          </table>
        </section>
      </div>
    </Container>
  )
}
