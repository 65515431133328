import styled from "styled-components"

import { colors } from '~/styles';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  width: 100%;

  > div {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    padding: 40px;
    width: 100%;
  }
`

export const Challenges = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;

  > section {
    background: ${colors.white};
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    padding: 40px 30px;
    margin-right: 100px;
    margin-bottom: 20px;

    strong {
      color: ${colors.primary};
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 20px;
      text-transform: uppercase;
    }

    span {
      color: ${colors.black};
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;

    > section {
      margin: 0 0 20px;
      width: 100%;
    }
  }
`;

export const Databases = styled.div`
  display: flex;
  flex-direction: row;

  > a {
    background: ${colors.white};
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-right: 20px;

    strong {
      color: ${colors.primary};
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 5px;
      text-transform: uppercase;
    }

    span {
      color: ${colors.black};
      font-size: 14px;
      font-weight: 300;
    }
  }
`;
